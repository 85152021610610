import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  appendInfo,
  popInfo,
  setInfo,
  setQuestion,
  setQuizDone,
  setSection,
} from "../../redux/actions";
import Question from "./Question";
import {
  answerChangeHandler,
  generateFields,
  generateHeader,
  getHighlightColor,
  getSprite,
  removeEnterKeyListener,
  setEnterKeyListener,
} from "./questionUtils.js";

// props:
// sectionIdx;
// questionIdx;

// mark style questions have a list of fields

class RankQuestion extends Question {
  genRow(fields, state) {
    const xs = this.props.question.fields.length >= 25;
    var cols = _.map(fields, (field) => {
      const markButtonCol = classNames("mark-button-col", "standard-border", {
        "mark-button-col-xs": xs,
      });

      const markButton = classNames("mark-button", {
        "mark-button-sm":
          this.props.question.fields.length >= 15 &&
          this.props.question.fields.length < 25,
        "mark-button-xs": xs,
      });

      const highlight = state.answer && _.includes(state.answer, field);

      var sprite;
      if (this.props.question.sprites) sprite = getSprite(this.props, field);

      return (
        <Col
          key={field}
          md={2}
          style={highlight ? { backgroundColor: getHighlightColor(field) } : {}}
          className={markButtonCol}
          onMouseEnter={() => this.props.appendInfo(field)}
          onMouseLeave={() => this.props.popInfo()}
        >
          <div
            id={field}
            className={markButton}
            onClick={() => this.handleSelection(field)}
          >
            <p className="noselect">
              {" "}
              {state.answer && _.includes(state.answer, field)
                ? state.answer.indexOf(field) + 1 + "." + field
                : field}
            </p>
            {this.props.question.sprites ? (
              <div
                className="sprite"
                style={{
                  content: sprite,
                }}
              />
            ) : null}
          </div>
        </Col>
      );
    });

    const paddingSize = this.getPaddingSize(this.props.question.fields.length);

    return (
      <Row className="mark-button-row">
        <Col md={paddingSize} />
        {cols}
        <Col md={paddingSize} />
      </Row>
    );
  }

  getPaddingSize(numFields) {
    if (numFields <= 3) {
      return 3;
    } else if (numFields >= 25) {
      //accomodates small mark buttons
      return 0;
    }
    return 1;
  }

  handleSelection(field) {
    var selections;
    if (!this.state.answer) {
      selections = [field];
      this.setState({ answer: selections });
    } else if (_.indexOf(this.state.answer, field) === -1) {
      selections = [...this.state.answer, field];
      this.setState({ answer: selections });
    } else {
      //unselect
      selections = _.filter(this.state.answer, (item) => item !== field);
      if (_.isEmpty(selections)) {
        this.setState({ answer: null });
      } else {
        this.setState({ answer: selections });
      }
    }
  }

  displayNextIfCompleteAnswer() {
    if (
      this.state.answer &&
      this.state.answer.length === this.props.question.fields.length
    ) {
      if (!this.state.shouldDisplayNext)
        this.setState({ shouldDisplayNext: true });
    } else {
      if (this.state.shouldDisplayNext)
        this.setState({ shouldDisplayNext: false });
    }
  }

  componentDidUpdate() {
    if (this.props.requireAll) {
      this.displayNextIfCompleteAnswer();
    } else {
      if (!this.state.shouldDisplayNext && this.state.answer)
        this.setState({ shouldDisplayNext: true });
      else if (this.state.shouldDisplayNext && !this.state.answer)
        this.setState({ shouldDisplayNext: false });
    }

    answerChangeHandler(this.props, this.state.answer);

    if (this.state.shouldDisplayNext)
      setEnterKeyListener(this.enterKeyListenerCallback);
    else removeEnterKeyListener(this.enterKeyListenerCallback);
  }

  render() {
    const fieldsPerRow = 5;

    return (
      <div>
        <div>{generateHeader(this.props, this.state)}</div>
        <Container className="answer-box">
          {generateFields(
            this.props,
            this.state,
            this.genRow.bind(this),
            fieldsPerRow
          )}
        </Container>
      </div>
    );
  }
}

export default connect(null, {
  setSection,
  setQuestion,
  setInfo,
  appendInfo,
  popInfo,
  setQuizDone,
})(RankQuestion);
