import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../css/landing.css";
import { getSelection } from "../redux/selectors";
import Dimensions from "./common/Dimensions";
import DimensionsNav from "./common/DimensionsNav";
import NavBar from "./common/NavBar";
import TitleBox from "./common/TitleBox";
import LandingRD from "./landing/LandingResearch";
import LandingStory from "./landing/LandingStory";
import MobileModal from "./landing/MobileModal";
import Playboard from "./landing/Playboard";

class Landing extends React.Component {
  render() {
    return (
      <div>
        <MobileModal />
        <Container fluid className="hhaw-container no-padding">
          <Row noGutters={true}>
            <Col md={5} className="standard-border ">
              <TitleBox />
              <DimensionsNav link />
            </Col>
            <Col md={7} className="landing-col">
              <NavBar />
              <div className="landing-content">
                <Playboard />
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="hhaw-container no-padding">
          {/* dimensions table */}
          <Row style={{ height: "2vh", backgroundColor: "white" }}></Row>
          <Dimensions selected={this.props.selected} />
        </Container>
        <Container fluid className="hhaw-container no-padding">
          {/* story section */}
          <Row style={{ height: "2vh" }}></Row>
          <LandingStory />
          <Row style={{ height: "2vh" }}></Row>
        </Container>
        <Container fluid className="hhaw-container no-padding">
          <LandingRD></LandingRD>
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    selected: getSelection(state),
  }),
  null
)(Landing);
