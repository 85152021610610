import { motion } from "framer-motion";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import "../css/dataviz.css";
import { getRows, getRowsSandbox, loadDb, loadSandboxDb } from "../db/db";
import NavBar from "./common/NavBar";
import TitleBox from "./common/TitleBox";
import Chart from "./dataviz/Chart";
import Preview from "./dataviz/Preview";
import Sankey from "./dataviz/Sankey";

class DataViz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <div>
        <Container fluid className="hhaw-container no-padding">
          <Row noGutters={true}>
            <Col md={5} className="landing-col">
              <TitleBox customTitle="learn about our health." />
              <Row
                noGutters={true}
                style={{
                  backgroundColor: "white",
                  height: "24vh",
                }}
              >
                <Col />
                <Col
                  className="d-flex flex-column justify-content-center"
                  md={3}
                >
                  {this.state.data.length > 0 ? (
                    <motion.div
                      style={{ opacity: 0 }}
                      whileHover={{ scale: 1.2 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 3 }}
                    >
                      <Link to="#dataviz">
                        <svg
                          className="bi bi-arrow-down-circle-fill"
                          width="4em"
                          height="4em"
                          viewBox="0 0 16 16"
                          fill="black"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 5a.5.5 0 0 0-1 0v4.793L5.354 7.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 9.793V5z"
                          />
                        </svg>
                      </Link>
                    </motion.div>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col />
              </Row>
            </Col>
            <Col md={7} className="landing-col d-flex flex-column">
              <NavBar />
              <Preview data={this.state.data} />
            </Col>
          </Row>
        </Container>
        <Container fluid className="no-padding hhaw-container">
          <Row noGutters={true} id="dataviz" className="dataviz-break">
            <Col />
            <Col md={10}>
              <h1>
                explore the data and learn about how we perceive health and
                wellness.
              </h1>
            </Col>
            <Col />
          </Row>
          <Chart data={this.state.data} />
          <Row style={{ minHeight: "1vh" }}></Row>
        </Container>
        {this.state.data.length > 0 ? (
          <Container fluid className="no-padding hhaw-container">
            <div id="sankey" className="dataviz-break">
              <Row noGutters={true}>
                <Col />
                <Col md={10}>
                  <h1>Vizualize our health</h1>
                </Col>
                <Col />
              </Row>
              <Row noGutters={true}>
                <Col />
                <Col md={10}>
                  <h3>
                    Play with the categories below and see how our data is
                    connected
                  </h3>
                </Col>
                <Col />
              </Row>
            </div>
            <Row>
              <Col />
              <Col md={10}>
                <Sankey key="sankey" data={this.state.data} />
              </Col>
              <Col />
            </Row>
          </Container>
        ) : null}
      </div>
    );
  }

  async componentDidMount() {
    var options = {};
    const env = process.env.NODE_ENV || "development";
    if (env === "development") {
      options.limit = 500;
    }
    var rows = [];
    if (process.env.REACT_APP_USE_SANDBOX === "true") {
      await loadSandboxDb();
      rows = await getRowsSandbox(options);
    } else {
      await loadDb();
      rows = await getRows(options);
    }

    this.setState({ data: rows });
  }
}

export default DataViz;
