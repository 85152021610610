import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { connect } from "react-redux";
import autocomplete from "../../matrices/autocompleteMatrix";
import {
  appendInfo,
  popInfo,
  setInfo,
  setQuestion,
  setQuizDone,
  setSection,
} from "../../redux/actions";
import PlaceTypeahead from "./PlaceTypeahead.js";
import Question from "./Question";
import {
  answerChangeHandler,
  generateHeader,
  removeEnterKeyListener,
  setEnterKeyListener,
} from "./questionUtils.js";

const timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
const numberRegex = /^\d+$/;

class InputQuestion extends Question {
  constructor(props) {
    super(props);
    if (this.props.question.fields) this.state.answer = {};
    if (this.props.question.inputType === "place")
      this.state.typeaheadOptions = [];
  }
  componentDidUpdate() {
    answerChangeHandler(this.props, this.state.answer);

    if (!this.props.question.autocomplete) {
      if (this.state.shouldDisplayNext)
        setEnterKeyListener(this.enterKeyListenerCallback);
      else removeEnterKeyListener(this.enterKeyListenerCallback);
    }
  }

  generateTypeahead(question) {
    return (
      <Typeahead
        multiple={question.multiple}
        id="typeahead"
        placeholder={question.placeholder}
        onBlur={() => this.onBlurHandler()}
        onChange={(selected) => this.handleInput(question, selected)}
        options={autocomplete[question.autocomplete]}
        selected={this.state.answer ? this.state.answer : []}
      />
    );
  }

  onBlurHandler() {
    if (this.state.shouldDisplayNext)
      setEnterKeyListener(this.enterKeyListenerCallback);
    else removeEnterKeyListener(this.enterKeyListenerCallback);
  }

  handleInput(question, value) {
    this.setState({
      answer: value,
      shouldDisplayNext: this.isValid(question, value),
    });
  }

  handleInputForm(question, field, value) {
    if (this.isValid(question, value)) {
      const answer = this.state.answer;
      answer[field] = value;

      if (_.keys(answer).length === question.fields.length)
        this.setState({ answer, shouldDisplayNext: true });
      else this.setState({ answer });
    }
  }

  isValid(question, value) {
    var isValid = false;
    if (value === "") {
      return isValid;
    }

    switch (question.inputType) {
      case "time":
        isValid = timeRegex.test(value);
        break;
      case "number":
        isValid = numberRegex.test(value);
        break;
      case "place":
      case "text":
        //place: normalization is done by the google place autocomplete
        //text: not exactly great, but i think the risk is relatively low
        isValid = true;
        break;

      default:
        alert("Question object malformed");
    }
    return isValid;
  }

  generateForm(question) {
    return (
      <div>
        <form>
          {_.map(question.fields, (field) => {
            return (
              <Row className="d-flex justify-content-center input-field">
                <Col />
                <Col md={5}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={field}
                    aria-label={field}
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      this.handleInputForm(question, field, e.target.value);
                    }}
                  />
                </Col>
                <Col />
              </Row>
            );
          })}
        </form>
      </div>
    );
  }

  generateSingleInput(question) {
    var input = (
      <input
        type="text"
        className="form-control"
        placeholder={question.placeholder}
        aria-label={question.placeholder}
        aria-describedby="basic-addon1"
        onChange={(e) => {
          this.handleInput(question, e.target.value);
        }}
        value={this.state.answer}
      />
    );

    if (question.autocomplete) {
      if (question.inputType === "place") {
        input = (
          <PlaceTypeahead
            question={question}
            onBlur={() => this.onBlurHandler()}
            onChange={(selected) => this.handleInput(question, selected)}
            answer={this.state.answer}
          />
        );
      } else input = this.generateTypeahead(question);
    }

    return (
      <div>
        {!question.multiple ? (
          <Row
            className="d-flex justify-content-center"
            style={{ height: "15vh" }}
          >
            <Col />
            <Col
              md="auto"
              style={{
                paddingTop: "10vh",
              }}
            >
              <div
                className="text-center"
                style={{
                  borderBottom: "1px solid",
                }}
              >
                {this.state.answer && !_.isEmpty(this.state.answer) ? (
                  <h2
                    style={{
                      marginLeft: "1vw",
                      marginRight: "1vw",
                    }}
                  >
                    {this.state.answer}
                  </h2>
                ) : (
                  <div />
                )}
              </div>
            </Col>
            <Col />
          </Row>
        ) : (
          <div></div>
        )}

        <Row style={{ minHeight: "20vh" }}>
          <Col />
          <Col md={4} style={{ paddingTop: "10vh" }}>
            {input}
          </Col>
          <Col />
        </Row>
      </div>
    );
  }

  render() {
    return (
      <div>
        {generateHeader(this.props, this.state)}

        <Container className="answer-box">
          {this.props.question.fields
            ? this.generateForm(this.props.question)
            : this.generateSingleInput(this.props.question)}
        </Container>
      </div>
    );
  }
}

export default connect(null, {
  setSection,
  setQuestion,
  setInfo,
  appendInfo,
  popInfo,
  setQuizDone,
})(InputQuestion);
