import gsheets from "google-spreadsheet";
import _ from "lodash";
import {
  // bioSectionLength,
  questionnaireLength,
} from "../constants/common.js";
import { getAnswerMatrix } from "../matrices/answerMatrix.js";
import questionMatrix from "../matrices/questionMatrix.js";

const GoogleSpreadsheet = gsheets.GoogleSpreadsheet;

// spreadsheet key is the long id in the sheets URL
const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEETS_ID_PROD);
const sandbox = new GoogleSpreadsheet(
  process.env.REACT_APP_GOOGLE_SHEETS_ID_SANDBOX
);

const headerValues = _.concat(
  _.map(questionMatrix[0].questions, "name"),
  _.map(_.range(1, questionnaireLength + 1), (i) => i.toString())
);

export const loadDb = async () => {
  await doc.useServiceAccountAuth({
    client_email: process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_EMAIL,
    private_key: process.env.REACT_APP_GOOGLE_SHEETS_KEY,
  });
  await doc.loadInfo();
  await doc.sheetsByIndex[0].setHeaderRow(headerValues);
};

export const loadSandboxDb = async () => {
  await sandbox.useServiceAccountAuth({
    client_email: process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_EMAIL,
    private_key: process.env.REACT_APP_GOOGLE_SHEETS_KEY,
  });
  await sandbox.loadInfo();
  await sandbox.sheetsByIndex[0].setHeaderRow(headerValues);
};

export const addQuizResultsForUser = async () => {
  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[0];
  const row = _(getAnswerMatrix())
    .map((o) => {
      if (o === null) {
        return "";
      } else if (Array.isArray(o)) {
        return o.join(",");
      } else if (_.isString(o)) {
        return o;
      } else {
        return JSON.stringify(o);
      }
    })
    .value();

  return await sheet.addRow(row);
};

export const addRow = async (row) => {
  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[0];
  return await sheet.addRow(row);
};

export const getRows = async (options = {}) => {
  const sheet = doc.sheetsByIndex[0];
  return await sheet.getRows(options);
};

export const getRowsSandbox = async (options = {}) => {
  const sheet = sandbox.sheetsByIndex[0];
  return await sheet.getRows(options);
};
