import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import "../../css/hoverinfo.css";
import matrix from "../../matrices/hoverInfoMatrix";
import { getInfo } from "../../redux/selectors";

class HoverInfo extends React.Component {
  genInfo() {
    return _.map(this.props.info, (tip) => {
      if (matrix[tip]) {
        return (
          <li className="tip">
            <h4>> {matrix[tip]} </h4>
          </li>
        );
      }
      return <div />;
    });
  }
  render() {
    return (
      <div className="sticky-top tip-list-wrapper">
        <ul className="tip-list">{this.genInfo()}</ul>
      </div>
    );
  }
}

export default connect((state) => ({
  info: getInfo(state),
}))(HoverInfo);
