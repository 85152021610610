import React from "react";
import { connect } from "react-redux";
import "../../css/question.css";
import questionMatrix from "../../matrices/questionMatrix.js";
import { getQuestion, getSection } from "../../redux/selectors.js";
import CircleQuestion from "../questionTypes/Circle";
// import DropMarkQuestion from "../questionTypes/DropMark";
import InputQuestion from "../questionTypes/Input";
import MarkQuestion from "../questionTypes/Mark";
import MarkCircleQuestion from "../questionTypes/MarkCircle";
import PercentageQuestion from "../questionTypes/Percentage";
// import MarkRankQuestion from "./question_types/MarkRank";
import RankQuestion from "../questionTypes/Rank";
// TODO consider making a module

// purpose is mostly to branch on question type

class QuestionDisplay extends React.Component {
  selectQuestionType(question) {
    const key = this.props.sectionIdx + "," + this.props.questionIdx;
    switch (question.type) {
      case "Input":
        return (
          <InputQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
          />
        );
      case "Mark":
        return (
          <MarkQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
          />
        );
      case "Circle":
        return (
          <CircleQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
          />
        );

      case "MarkRank":
        return (
          <RankQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
          />
        );
      case "Rank":
        return (
          <RankQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
            requireAll
          />
        );
      case "Percentage":
        return (
          <PercentageQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
          />
        );
      case "MarkCircle":
        return (
          <MarkCircleQuestion
            key={key}
            question={question}
            sectionIdx={this.props.sectionIdx}
            questionIdx={this.props.questionIdx}
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const question =
      questionMatrix[this.props.sectionIdx].questions[this.props.questionIdx];
    return (
      <div className="container-fluid question-container">
        {this.selectQuestionType(question)}
      </div>
    );
  }
}

export default connect((state) => ({
  question: getQuestion(state),
  section: getSection(state),
}))(QuestionDisplay);
