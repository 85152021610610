import React from "react";
import { Link } from "react-router-dom";

function QuestionMark(props) {
  return (
    <Link to="/rd" target="_blank" className="text-center">
      <div className="question-box standard-border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="263.328"
          height="438.229"
          viewBox="0 0 263.328 438.229"
          fill="currentColor"
        >
          <g
            id="Group_35"
            data-name="Group 35"
            transform="translate(-25.862 -25.814)"
          >
            <path
              id="Path_1136"
              data-name="Path 1136"
              d="M171.315,25.814c6.791,1.225,13.732,1.92,20.351,3.763,45.729,12.718,78.581,39.923,92.224,86.361,8.838,30.083,6.625,60.237-6.635,89.079-6.289,13.686-15.619,24.949-28.416,33.042-8.322,5.261-16.83,10.245-25.422,15.057a106.967,106.967,0,0,0-23.678,17.549,38.122,38.122,0,0,0-11.6,28.223c.057,10.957.017,21.918.017,32.879v3.76H129.389c-.11-.236-.393-.552-.389-.865.206-15.938,0-31.9.812-47.809.546-10.724,3.757-21.056,8.3-30.842,4.406-9.483,10.844-17.443,19.8-22.88,14.548-8.838,29.039-17.862,44.135-25.685,18.041-9.347,27.039-24.314,29.734-43.6,2.05-14.654,1.424-29.225-5.271-42.851-10.1-20.55-27.129-32.622-48.97-37.51-24.364-5.454-47.6-2.476-67.694,13.673C91.6,111.822,82.9,131.184,83.254,154.6c.007.482,0,.965,0,1.777H25.862c.356-7.2.306-14.468,1.131-21.635C33.592,77.4,81.407,33.777,137.485,26.679c1.784-.226,3.554-.576,5.331-.865Z"
              transform="translate(0 0)"
            />
            <path
              id="Path_1137"
              data-name="Path 1137"
              d="M132.015,173A38.8,38.8,0,1,1,93.3,134.141,38.847,38.847,0,0,1,132.015,173Z"
              transform="translate(66.456 252.126)"
            />
          </g>
        </svg>
      </div>
    </Link>
  );
}

export default QuestionMark;
