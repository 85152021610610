import React from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../css/quiz.css";
import { addQuizResultsForUser, loadDb } from "../db/db";
import { reset } from "../matrices/answerMatrix";
import { resetQuiz, setInfo } from "../redux/actions";
import { getQuestion, getSection, quizDone } from "../redux/selectors";
import QuizSection from "./quiz/QuizSection";

// state:
// section number

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    // set the answer matrix on start
    reset();
  }

  render() {
    return (
      <Container fluid>
        <Modal className="finish-modal" show={this.props.quizDone}>
          <Modal.Header>
            <Modal.Title>Thanks for completing the questionnaire!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Your results have been saved. Click{" "}
              <Link style={{ color: "pink" }} to="/data">
                here
              </Link>{" "}
              to see how others responded.
            </p>
            <p>
              {" "}
              Or, return to the{" "}
              <Link style={{ color: "pink" }} to="/">
                home page
              </Link>
              .{" "}
            </p>
          </Modal.Body>
        </Modal>
        <Row>
          <QuizSection sectionIdx={this.props.section} />
        </Row>
      </Container>
    );
  }

  async componentDidMount() {
    await loadDb();
  }

  async componentDidUpdate() {
    if (this.props.quizDone) {
      await addQuizResultsForUser();
      console.log("saved!");
    }
  }

  componentWillUnmount() {
    this.props.resetQuiz();
    reset();
  }
}

export default connect(
  (state) => ({
    section: getSection(state),
    question: getQuestion(state),
    quizDone: quizDone(state),
  }),
  { setInfo, resetQuiz }
)(Quiz);
