import React from "react";
import { render } from "react-dom";
import { createStore } from "redux";
import reducers from "./redux/reducers";
import Root from "./Root";

// Could use if this behavior is desired
//
// const questionnaireState = loadState();
// const persistedState = {
//   questionnaire: questionnaireState,
//   hoverInfo: []
// }

const store = createStore(reducers);

// store.subscribe(() => {
//   saveState({
//     section: store.getState().questionnaire.section,
//     question: store.getState().questionnaire.question,
//   });
// });

render(<Root store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
