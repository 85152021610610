import _ from "lodash";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import "../../css/accordion.css";
import HHAWCard from "./AccordionCard.js";

function generateCards(activeKey, content) {
  const cards = _.flatMap(content, (section) =>
    _.map(section.questions, (question) => {
      return (
        <HHAWCard
          reportSectionName={section.name}
          activeKey={activeKey}
          key={question.idx}
          idx={question.idx}
          title={question.name}
        />
      );
    })
  );

  return cards;
}

function HHAWAccordion(props) {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <Accordion
      id="accordion"
      className="hhaw-accordion"
      activeKey={activeKey}
      onSelect={(e) => setActiveKey(e)}
    >
      {generateCards(activeKey, props.content)}
    </Accordion>
  );
}

export default HHAWAccordion;
