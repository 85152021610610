import _ from "lodash";
import React from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../css/quiz.css";
import questionMatrix from "../../matrices/questionMatrix.js";
import { setQuestion, setSection } from "../../redux/actions";
import { getQuestion } from "../../redux/selectors";
import BackButton from "../common/BackButton";
import HoverInfo from "./HoverInfo.js";
import QuestionDisplay from "./QuestionDisplay.js";

const numQuestions = _.reduce(
  questionMatrix.slice(1),
  (sum, section) => sum + section.questions.length,
  0
);

class QuizSection extends React.Component {
  genQuestionList() {
    const activeQuestion =
      questionMatrix[this.props.sectionIdx].questions[this.props.question];
    const subSections = _(questionMatrix[this.props.sectionIdx].questions)
      .map((q) => {
        return q.section_name ? q.section_name : q.name;
      })
      .uniq()
      .value();

    return _.map(subSections, (subSection) => {
      if (
        subSection === activeQuestion.section_name ||
        subSection === activeQuestion.name
      ) {
        return (
          <li className="section-progress-element current-subsection-wrapper">
            <h4> {subSection}. </h4>
          </li>
        );
      } else {
        return (
          <li className="section-progress-element">
            <h4> {subSection}. </h4>
          </li>
        );
      }
    });
  }

  backHandler(props) {
    const prevQuestionIdx = props.question - 1;
    const prevSectionIdx = props.sectionIdx - 1;
    if (prevQuestionIdx > -1) {
      this.props.setQuestion(prevQuestionIdx);
    } else if (prevSectionIdx > -1) {
      const lastQuestionPrevSection =
        questionMatrix[prevSectionIdx].questions.length - 1;
      this.props.setQuestion(lastQuestionPrevSection);
      this.props.setSection(prevSectionIdx);
    }
  }

  render() {
    const question =
      questionMatrix[this.props.sectionIdx].questions[this.props.question];
    var progress = 0;
    if (question.idx) {
      progress = Math.round(((question.idx - 1) / numQuestions) * 100);
    }

    var sectionHeaderFontSize = "calc(2.0rem + 0.3vw)";
    if (questionMatrix[this.props.sectionIdx].name.length >= 8) {
      sectionHeaderFontSize = "calc(1.2rem + 0.5vw";
    }
    if (questionMatrix[this.props.sectionIdx].name.length >= 10) {
      sectionHeaderFontSize = "calc(0.6rem + 0.8vw)";
    }

    return (
      <Container fluid className="no-padding">
        <Row noGutters={true}>
          <Col md={1} className="text-center">
            {this.props.sectionIdx === 0 && this.props.question === 0 ? (
              <Link to="/">
                <BackButton />
              </Link>
            ) : (
              <div onClick={() => this.backHandler(this.props)}>
                <BackButton />
              </div>
            )}
          </Col>
          <Col md={3}></Col>
          <Col md={4} className="d-flex flex-column justify-content-center">
            {question.idx ? (
              <ProgressBar animated now={progress} label={`${progress}%`} />
            ) : null}
          </Col>
          <Col></Col>
        </Row>
        <Row noGutters={true}>
          <Col md={1} className="text-center">
            <div className="section-number">
              <h2> {this.props.sectionIdx}_ </h2>
            </div>
          </Col>
          {/* Progress section on the left */}
          <Col md={2} className="standard-border">
            <div className="section-header">
              <h2 style={{ fontSize: sectionHeaderFontSize }}>
                {questionMatrix[this.props.sectionIdx].name}{" "}
              </h2>
            </div>
            <ul
              className="section-progress-list"
              style={{ listStyle: "none", marginTop: "3vh" }}
            >
              {this.genQuestionList()}
            </ul>
          </Col>
          {/* Question box */}
          <Col md={6}>
            <QuestionDisplay
              questionIdx={this.props.question}
              sectionIdx={this.props.sectionIdx}
            />
          </Col>
          <Col md={2} className="standard-border">
            <HoverInfo />
          </Col>
          <Col md={1} />
        </Row>
        <Row noGutters={true}>
          <Col md={9}></Col>
          <Col md={2}>
            <a href="mailto:milgc503@newschool.edu">
              <div className="feedback-div standard-border invert-color">
                <h5 className="noselect">Feedback.</h5>
              </div>
            </a>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    question: getQuestion(state),
  }),
  { setSection, setQuestion }
)(QuizSection);
