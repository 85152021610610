import _ from "lodash";
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

const previewDataPoints = [
  "total",
  "hugs",
  "anxietyAndSex",
  "american",
  "tech",
  "homemade",
  "belief",
  "socialMedia",
  "idealized",
];

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.length < this.props.data.length) {
      this.generatePreviews();
    }
  }

  generatePreviews() {
    var previews = _.reduce(
      previewDataPoints,
      (aggregate, key) => {
        aggregate[key] = 0;
        return aggregate;
      },
      {}
    );

    previews.total = this.props.data.length;

    // these magic numbers are based on question matrix
    _.forEach(this.props.data, (row) => {
      //hugs question
      var touchAns = row[27];
      var rare = [
        "A few times a month",
        "Once a month",
        "Once every few months",
      ];
      if (rare.includes(touchAns)) previews.hugs += 1;

      // feeling question
      var anxietyAndSexAns = row[19].split(",");
      if (
        _.intersection(anxietyAndSexAns, ["Anxiety", "Sexual desire"])
          .length === 2
      )
        previews.anxietyAndSex += 1;

      // I dont know yet I want to support multiple nationalities
      var nationality = row["Nationality"].split(",");

      if (
        _.intersection(
          ["United States of America", "United States"],
          nationality
        ).length > 0
      )
        previews.american += 1;

      // tech industry
      var vocation = row[42].split(",");
      if (
        _.intersection(
          [
            "Computer & Network Security",
            "Computer Games",
            "Computer Hardware",
            "Computer Networking",
            "Computer Software",
          ],
          vocation
        ).length > 0
      )
        previews.tech += 1;

      // homemade meals
      var meals = row[9];
      if (meals === "Homemade") previews.homemade += 1;

      // belief
      var belief = row[26];
      if (belief === "Atheism") previews.belief += 1;

      //social media
      var socialMedia = row[36];
      var often = ["Multiple times an hour", "Hourly"];
      if (often.includes(socialMedia)) previews.socialMedia += 1;

      //  idealized health
      var idealized = row["Idealized Health"];
      if (idealized === "Idealized Healthy Person") previews.idealized += 1;
    });

    this.setState({ previews });
  }

  getPreview(key) {
    if (this.state.previews) {
      return (
        <div className="preview-count">
          {" "}
          <h3>{this.state.previews[key]}</h3>
        </div>
      );
    } else {
      return <Spinner animation="border" />;
    }
  }

  render() {
    return (
      <Row noGutters={true}>
        <Col />
        <Col md={9}>
          <Row className="preview-buffer"></Row>
          <Row className="preview-row">
            <Col className="standard-border">
              {this.getPreview("total")}
              <p className="preview-caption">people have taken this survey. </p>
            </Col>
            <Col className="standard-border">
              {this.getPreview("hugs")}
              <p className="preview-caption">
                people haven't had a hug recently.{" "}
              </p>
            </Col>
            <Col className="standard-border">
              {this.getPreview("anxietyAndSex")}
              <p className="preview-caption">
                people feel anxiety & sexual desire.{" "}
              </p>
            </Col>
          </Row>
          <Row className="preview-row">
            <Col className="standard-border">
              {this.getPreview("american")}
              <p className="preview-caption">people are American. </p>
            </Col>
            <Col className="standard-border">
              {this.getPreview("tech")}
              <p className="preview-caption">
                people work or desire to work in the tech industry.{" "}
              </p>
            </Col>
            <Col className="standard-border">
              {this.getPreview("homemade")}
              <p className="preview-caption">
                people had primarily homemade meals.{" "}
              </p>
            </Col>
          </Row>
          <Row className="preview-row">
            <Col className="standard-border">
              {this.getPreview("belief")}
              <p className="preview-caption">people are atheists. </p>
            </Col>
            <Col className="standard-border">
              {this.getPreview("socialMedia")}
              <p className="preview-caption">
                people use social media every hour or even more frequently.{" "}
              </p>
            </Col>
            <Col className="standard-border">
              {this.getPreview("idealized")}
              <p className="preview-caption">
                filled out this survey as their "idealized" healthy person.
              </p>
            </Col>
          </Row>
          <Row className="preview-buffer"></Row>
        </Col>
        <Col />
      </Row>
    );
  }
}

export default Preview;
