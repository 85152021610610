import React from "react";
import { Col, Row } from "react-bootstrap";
import "../../css/landingRD.css";
import CircleRotate from "../common/CircleRotate";

export default function LandingRD(props) {
  return (
    <div>
      <Row className="rd-row">
        <Col>
          <h1 className="rd-header">Research and Development</h1>
        </Col>
      </Row>
      <Row noGutters={true} className="rd-row">
        <Col></Col>
        <Col md="auto" className="rd-col-1">
          <div className="rd-left-square">
            <CircleRotate
              outerColor="#e8392e"
              innerColor="white"
              text="In order to understand the 8 Dimensions of Wellness HHAW used
            these sources:"
              useH1
            />
          </div>
        </Col>
        <div style={{ width: "6vw" }}></div>
        <Col md="auto" className="rd-col-2">
          <Row>
            <div
              style={{ backgroundColor: "#3B4696" }}
              className="rd-right-square"
            >
              <CircleRotate
                outerColor="#3B4696"
                innerColor="#E8392E"
                text="Scientific Literature, Studies, & Theories"
              />
            </div>

            <div
              style={{ backgroundColor: "#0768DB" }}
              className="rd-right-square blue-circle-overflow"
            >
              <CircleRotate
                outerColor="#0768DB"
                innerColor="#66CCFF"
                text=" Psychologists, Linguists, Consultants, Architects, Data
              Scientists, & more."
              />
            </div>
          </Row>
          <Row>
            <div
              style={{ backgroundColor: "#52AF78" }}
              className="rd-right-square pink-circle-overflow"
            >
              <CircleRotate
                outerColor="#52AF78"
                innerColor="#F6ADC4"
                text="Health Experts, physicians, doctors, acupuncturists, & more."
              />
            </div>

            <div
              style={{ backgroundColor: "#CC6600" }}
              className="rd-right-square"
            >
              <CircleRotate
                outerColor="#CC6600"
                innerColor="#F0B41E"
                text="Graphic Designers, UI/UX Designers, & more."
              />
            </div>
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}
