import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../css/question.css";
import { setAnswer } from "../../matrices/answerMatrix.js";
import { colors } from "../../matrices/colorPalette.js";
import questionMatrix from "../../matrices/questionMatrix.js";
import QuestionMark from "../common/QuestionMark";

export function nextHandler(props) {
  const nextQuestionIdx = props.questionIdx + 1;
  const nextSectionIdx = props.sectionIdx + 1;
  if (nextQuestionIdx < questionMatrix[props.sectionIdx].questions.length) {
    props.setQuestion(nextQuestionIdx);
  } else {
    props.setQuestion(0);
    props.setSection(nextSectionIdx);
  }
}

export function getHighlightColor(key) {
  var hash = 0;
  for (var i = 0; i < key.length; i++) {
    var char = key.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  //TODO: consider a more efficient way than to recompute this

  return colors[Math.abs(hash) % colors.length];
}

export function answerChangeHandler(props, newAnswer) {
  setAnswer(props.sectionIdx, props.questionIdx, newAnswer);
}

export function generateFields(props, state, genRow, fieldsPerRow) {
  return _.map(
    _.chunk(
      questionMatrix[props.sectionIdx].questions[props.questionIdx].fields,
      fieldsPerRow
    ),
    (fieldSet) => {
      return genRow(fieldSet, state);
    }
  );
}

export function getSprite(props, field) {
  const spritePath = _.join(
    [
      "sprites",
      questionMatrix[props.sectionIdx].name,
      props.question.bioIdx || props.question.idx,
      props.question.sprites[field],
    ],
    "/"
  );
  return `url("${spritePath}")`;
}

export function generateHeader(props, state) {
  const question =
    questionMatrix[props.sectionIdx].questions[props.questionIdx];

  const endOfQuiz =
    props.sectionIdx === questionMatrix.length - 1 &&
    props.questionIdx === questionMatrix[props.sectionIdx].questions.length - 1;

  // const env = process.env.NODE_ENV || "development";
  return (
    <Container className="header">
      <Row>
        <Col md={10}>
          <h2 className="prompt">> {question.prompt}</h2>
        </Col>
        <Col md={2}>
          <QuestionMark />
        </Col>
      </Row>
      <Row>
        {endOfQuiz ? (
          generateFinish(props)
        ) : state.shouldDisplayNext ? (
          generateNext(props)
        ) : (
          <div />
        )}
      </Row>
    </Container>
  );
}

export function setEnterKeyListener(cb) {
  document.addEventListener("keyup", cb);
}

export function removeEnterKeyListener(cb) {
  document.removeEventListener("keyup", cb);
}

function generateNext(props) {
  return (
    <div
      className="next-div standard-border"
      onClick={() => nextHandler(props)}
    >
      <h5 className="noselect">NEXT</h5>
    </div>
  );
}

function generateFinish(props) {
  return (
    <div
      className="next-div standard-border"
      onClick={() => props.setQuizDone()}
    >
      <h5 className="noselect">FINISH</h5>
    </div>
  );
}
