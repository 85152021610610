import _ from "lodash";
import React, { useState } from "react";
import { Carousel, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const leftCarouselColors = [
  "#F6ADC4",
  "#C3DCBF",
  "#F0B41E",
  "#AF4697",
  "#E8392E",
];

const rightCarouselColors = [
  "#0038F4",
  "#D01A4B",
  "#52AF78",
  "#3B4696",
  "#66CCFF",
];

const leftCarouselContent = ["problem 1.", "problem 2.", "problem 3."];

const rightCarouselContent = [
  "<p><b> So how healthy are we? </b> It’s a generic yet pivotal question. Initially, in doing research we had one north star guiding our investigation – explore and discover the industry of Health and Wellness – which if you didn’t know is huge. It encompasses a variety of industries from yoga, healthy eating, personal care, integrative medicine, cosmetics, tourism, weight-loss, meditation, workplace wellness, to name a few.</p><p>Overall the Global Wellness Industry was valued at 4.2 Trillion in 2017 – since then it’s only grown. Especially now, in the midst of Covid-19, it has only exponentially grown. As people are staying home and focusing on the true meaning of health. In doing this research we discovered 3 huge problems.</p>",
  "<p><b>First, what does “healthy” actually mean? </b> Does it simply mean free of disease? Do you need to look like Dwayne the Rock Johnson? There isn’t a set definition or perception that defines what healthy looks like, simply consider all the different diets out there that all claim to be the healthiest for you. From vegetarian, vegan, pescetarian, gluten-free, sugar-free, to Keto diet, there isn’t a single definition.</p>",
  "<p>Second, <b> what is “healthy?” </b> The growingly ambiguous perception of what “healthy” is reflected in the expanding industry of health and wellness.” As seen in the infinite amount of products that are being seductively promoted at us with claims and certifications denoting benefit to our lives, we as consumers inherently want to achieve “healthy,” but it begs the question of how.</p>",
  "<p><b> Is there one solution to being healthy? </b> Everyone including your doctors have different opinions. According to a study nearly 87% of the time a patient receives a second opinion from another doctor the diagnosis differs from the original. Among the 286 patients studied, “66% received a refined or refined diagnosis, while 21% were diagnosed with something completely different than what their first physician concluded.”</p>",
];

function getLeftCarouselItems() {
  return _.map(_.range(0, leftCarouselContent.length), (i) => {
    const content = leftCarouselContent[i];
    return (
      <Carousel.Item key={_.join(["left", i], "-")}>
        <h1 className="our-story-header">{content}</h1>
      </Carousel.Item>
    );
  });
}

function getRightCarouselItems() {
  return _.map(_.range(0, rightCarouselContent.length), (i) => {
    const content = rightCarouselContent[i];
    return (
      <Carousel.Item
        key={_.join(["left", i], "-")}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  });
}

export default function LandingStory(props) {
  const [index, setIndex] = useState(0);

  return (
    <Row noGutters={true} className="landing-story-row">
      <Col className="landing-story-col-1">
        <Carousel
          className="landing-story-carousel"
          style={{ backgroundColor: leftCarouselColors[index] }}
          interval={15000}
          nextIcon={null}
          prevIcon={null}
          activeIndex={index}
          onSelect={(key, e) => setIndex(key)}
        >
          <Carousel.Item
            style={{
              paddingLeft: "5%",
            }}
            key="left-0"
          >
            <Row>
              <h1 className="our-story-header">our story.</h1>
            </Row>
            <Row style={{ height: "35%" }}></Row>
            <Row>
              <h3
                style={{
                  paddingLeft: "3%",
                }}
              >
                The many faces of healthy.
              </h3>
            </Row>
          </Carousel.Item>
          {getLeftCarouselItems(props)}
          <Carousel.Item key="left-4">
            <Row style={{ marginTop: "5%" }}>
              <Col md={6}>
                <div className="story-logo">
                  <Image alt="logo" fluid />
                </div>
              </Col>

              <Col md={6}>
                <h1 className="our-story-header">start.</h1>
              </Col>
            </Row>
            <Row style={{ height: "50vh" }}>
              <Col md={5}></Col>
              <Col md={7}>
                <p
                  style={{
                    position: "absolute",
                    bottom: "15%",
                    right: "10%",
                    paddingLeft: "5%",
                  }}
                >
                  From our research, we developed a set of questions that gets
                  to the core of wellness.
                </p>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Col>
      <div style={{ width: "5vw" }}></div>
      <Col className="landing-story-col-2">
        <Carousel
          className="landing-story-carousel"
          style={{
            backgroundColor: rightCarouselColors[index],
          }}
          interval={15000}
          nextIcon={null}
          prevIcon={null}
          activeIndex={index}
          onSelect={(key, e) => setIndex(key)}
        >
          {getRightCarouselItems(props)}
          <Carousel.Item key="right-4" className="end-slide-right">
            <Row>
              <Col></Col>
              <Col md={10}>
                <h2 className="our-story-header-end">
                  Considering these 3 questions we launched our research
                  collective
                  <b> How Healthy Are We? </b> with the aim of understanding
                  what is at the core of our perception in addition to what
                  influences it.
                </h2>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col lg={6} md={8}>
                <Link to="/story">
                  <div className="hhaw-button standard-border">
                    <h3> learn more</h3>
                  </div>
                </Link>
              </Col>
              <Col></Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  );
}
