import _ from "lodash";
import Plotly from "plotly.js/dist/plotly-cartesian";
import React from "react";
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Spinner,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import createPlotlyComponent from "react-plotlyjs";
import questionMatrix from "../../matrices/questionMatrix.js";

const Plot = createPlotlyComponent(Plotly);

const xExclusions = ["Substances", "Elements Of Health", "Meal Priorities"];
const yExclusions = ["Percentage", 13];

const xVariables = _.chain(questionMatrix[0].questions)
  .map("name")
  .without(...xExclusions)
  .value();

const yVariables = _.chain(questionMatrix.slice(1))
  .flatMap((section) => section.questions)
  .filter(
    (question) =>
      !(
        yExclusions.includes(question.type) ||
        yExclusions.includes(question.idx)
      )
  )
  .reduce((mapping, question) => {
    mapping[question.prompt] = question.idx;
    return mapping;
  }, {})
  .value();

const env = process.env.NODE_ENV || "development";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    const defaultYVar = "How are you feeling as of late?";
    this.state = {
      xVar: "Age",
      yVar: defaultYVar,
      yVarIdx: yVariables[defaultYVar],
      traces: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.yVar !== this.state.yVar) {
      this.setState({ yVarIdx: yVariables[this.state.yVar] });
    }

    if (
      prevState.xVar !== this.state.xVar ||
      prevState.yVarIdx !== this.state.yVarIdx ||
      prevProps.data.length < this.props.data.length
    ) {
      this.generateTraces();
    }
  }

  generateTraces() {
    var traceTable = {};

    var rows = this.props.data;
    if (env === "development") {
      rows = _.filter(
        rows,
        (row) => row[this.state.xVar] && row[this.state.yVarIdx]
      );
    }

    // TODO this happens on every state change though, do we need to read the rows on every state change?
    _.forEach(rows, (row) => {
      const x = row[this.state.xVar];
      const yVals = row[this.state.yVarIdx].split(",");
      _.forEach(yVals, (field) => {
        if (!traceTable[field]) traceTable[field] = {};

        if (traceTable[field][x]) traceTable[field][x] += 1;
        else traceTable[field][x] = 1;
      });
    });

    const traces = _.map(traceTable, (fieldMeta, field) => {
      return {
        x: _.keys(fieldMeta),
        y: _.values(fieldMeta),
        name: field,
        type: "bar",
      };
    });

    this.setState({ traces });
  }

  genDropdownFields(fields, onClick) {
    return _.map(fields, (field, i) => (
      <Dropdown.Item key={field} eventKey={i} onClick={() => onClick(field)}>
        {field}
      </Dropdown.Item>
    ));
  }

  genPlot(traces, xVar, yVar) {
    return (
      <Plot
        className="dataviz-plot"
        data={[...traces]}
        layout={{
          autosize: true,
          title: xVar + " vs. " + yVar,
          font: { family: "CircularStd" },
          yaxis: { title: "Number of Responses", tickWidth: 1 },
          hovermode: "closest",
        }}
        config={{ responsive: true }}
      />
    );
  }

  handleTypeaheadChange(value) {
    if (yVariables[value]) {
      this.setState({ yVar: value });
    }
  }

  genPage() {
    return (
      <div id="chart">
        <Row noGutters={true}>
          <Col />
          <Col md={10}>
            <div className="standard-border data-display-wrapper">
              <div className="data-display">
                {this.genPlot(
                  this.state.traces,
                  this.state.xVar,
                  this.state.yVar
                )}
              </div>
            </div>
          </Col>
          <Col />
        </Row>
        <Row noGutters={true}>
          <Col />
          <Col md={10}>
            <Row className="d-flex justify-content-center">
              <h4>Compare</h4>
              <DropdownButton
                as={ButtonGroup}
                variant="secondary"
                title={this.state.xVar}
                style={{ paddingLeft: "1%", paddingRight: "1%" }}
              >
                {this.genDropdownFields(xVariables, (variable) =>
                  this.setState({ xVar: variable })
                )}
              </DropdownButton>
              <h4> to</h4>
              <Typeahead
                dropup
                id="typeahead"
                style={{ paddingLeft: "1%", paddingRight: "1%", width: "50%" }}
                placeholder={"Question"}
                onChange={(selected) => this.handleTypeaheadChange(selected[0])}
                options={_.keys(yVariables)}
                selected={this.state.yVar ? [this.state.yVar] : []}
              />
            </Row>
          </Col>

          <Col />
        </Row>
      </div>
    );
  }

  render() {
    if (_.isEmpty(this.props.data)) {
      return (
        <Row noGutters={true}>
          <Col />
          <Col md={1}>
            <Spinner className="dataviz-spinner" animation="border" />
          </Col>
          <Col />
        </Row>
      );
    } else {
      return this.genPage();
    }
  }
}

export default Chart;
