export default [
  {
    name: "BIO",
    questions: [
      {
        bioIdx: 1,
        name: "Age",
        prompt: "How old are you?",
        type: "Input",
        placeholder: "Age",
        inputType: "number",
      },
      {
        bioIdx: 2,
        name: "Sex",
        prompt: "What is your Sex?",
        type: "Circle",
        fields: ["Female", "Male", "Prefer not to state"],
      },
      {
        bioIdx: 3,
        name: "Nationality",
        prompt: "What is your Nationality?",
        type: "Input",
        placeholder: "Nationality",
        inputType: "text",
        autocomplete: "countries",
        multiple: false,
      },
      {
        bioIdx: 4,
        section_name: "Residence",
        name: "City",
        prompt: "What is the name of the city you reside in currently?",
        type: "Input",
        inputType: "place",
        autocomplete: "cities",
        placeholder: "Search for your city...",
      },
      {
        bioIdx: 5,
        section_name: "Residence",
        name: "Residence Environment",
        prompt: "What kind of environment is your residence?",
        type: "Circle",
        fields: ["Urban", "Suburban", "Rural"],
        sprites: {
          Urban: "Urban.svg",
          Suburban: "Suburban.svg",
          Rural: "Rural.svg",
        },
      },
      {
        bioIdx: 6,
        section_name: "Priorities",
        name: "Meal Priorities",
        prompt: "What are your priorities when shopping for a meal?",
        type: "Rank",
        fields: [
          "Brand",
          "Sustainability",
          "Versatility",
          "Durability",
          "Aesthetic",
          "Quality",
          "Price",
          "Convenience",
        ],
        sprites: {
          Brand: "Brand.svg",
          Sustainability: "Sustainability.svg",
          Versatility: "versatility.svg",
          Durability: "Perishability.svg",
          Aesthetic: "Aesthetic.svg",
          Quality: "Quality.svg",
          Price: "Price.svg",
          Convenience: "Convenience.svg",
        },
      },
      {
        bioIdx: 7,
        section_name: "Culture",
        name: "Geographic Culture",
        prompt: "What geographical culture(s) do you identify with?",
        type: "Input",
        placeholder: "Countries",
        inputType: "text",
        autocomplete: "countries",
        multiple: true,
      },

      {
        bioIdx: 8,
        name: "Substances",
        prompt: "How regularly do you consume these, if at all?",
        type: "MarkCircle",
        fields: [
          "Alcohol",
          "Marijuana",
          "Opioids",
          "Sarin",
          "Hallucinogens",
          "Nicotine",
          "Stimulants",
          "Depressants",
          "Dissociatives",
          "Inhalants",
          "Prescribed Medication",
        ],
        sprites: {
          Alcohol: "Liquor.svg",
          Marijuana: "Weed.svg",
          Opioids: "Opioids.svg",
          Sarin: "Sarin.svg",
          Hallucinogens: "Hallucinogens.svg",
          Nicotine: "Nicotine.svg",
          Stimulants: "Stimulants.svg",
          Depressants: "Depressants.svg",
          Dissociatives: "Dissociatives.svg",
          Inhalants: "Inhalants.svg",
          "Prescribed Medication": "PrescribedMeds.svg",
        },
        subFields: {
          "X times a day": "#66CCFF",
          Everyday: " #EC8BA0",
          "X times a week": "#5664CB",
          "Once a week": "#00953B",
          "X times a month": "#F0B41E",
          "Once a month": "#0038F4",
          "Once every few months": "#E67504",
          Never: "#DA0031",
        },
      },

      {
        bioIdx: 9,
        section_name: "Usage",
        name: "Screen Time",
        prompt: "What is your daily screen time average (any device)?",
        type: "Input",
        placeholder: "hh:mm",
        inputType: "time",
      },
      {
        bioIdx: 10,
        section_name: "Health",
        name: "Elements Of Health",
        prompt:
          "What elements of health do you think are the most important to achieving a healthy lifestyle?",
        type: "Mark",
        fields: [
          "Diet & Nutrition",
          "Sleep",
          "Physical Health",
          "Physical Beauty",
          "Happiness",
          "Emotional Awareness",
          "Self Care",
          "Drugs & Alcohol",
          "Purpose",
          "Strong Values",
          "Relationships",
          "Support System",
          "Community",
          "Popularity",
          "Our Immediate Environment",
          "Exposure to Nature",
          "Sustainability",
          "Fufillment",
          "Financial Management",
          "Financial Means",
          "Creativity",
          "Learning new things",
          "Mental & Creative Stimulation",
        ],
      },
      {
        bioIdx: 11,
        section_name: "Mindset",
        name: "Idealized Health",
        prompt:
          "Do you want to fill this out as your idealized “healthy” person or as yourself?",
        type: "Circle",
        fields: ["Myself", "Idealized Healthy Person"],
      },
    ],
  },
  {
    name: "PHYSICAL",
    questions: [
      {
        idx: 1,
        name: "Diet Conviction",
        prompt: "Do you consider yourself healthy?",
        type: "Circle",
        fields: ["Yes", "No"],
      },
      {
        idx: 2,
        name: "Diet Conviction",
        prompt:
          "Do you know what you're eating for dinner tonight and tomorrow?",
        type: "Circle",
        fields: ["Yes", "No"],
      },
      {
        idx: 3,
        name: "Diet Conviction",
        prompt: "Where do you normally get your health information?",
        type: "MarkRank",
        fields: [
          "Government Sourced Information",
          "News",
          "Social Media",
          "Blogs",
          "Word of mouth",
          "Forums",
          "Academia",
          "Doctor",
          "Personal Experience",
        ],
        sprites: {
          "Government Sourced Information": "GovBigBro.svg",
          News: "News.svg",
          "Social Media": "SocialMedia.svg",
          Blogs: "Blog.svg",
          "Word of mouth": "WordofMouth.svg",
          Forums: "Forum.svg",
          Academia: "Academia.svg",
          Doctor: "Doctor.svg",
          "Personal Experience": "Meep.svg",
        },
      },
      {
        idx: 4,
        name: "Diet Conviction",
        prompt:
          "Excluding time and money, is there anything that motivates your current diet?",
        type: "MarkRank",
        fields: [
          "Weight Loss",
          "Building Muscle",
          "Sustainability",
          "No animals",
          "Longevity",
          "Religion",
          "Health",
          "Medical",
          "Taste",
          "None",
        ],
        sprites: {
          "Weight Loss": "weightloss.svg",
          "Building Muscle": "musclebuild.svg",
          Sustainability: "sustainability.svg",
          "No animals": "saveAnimals.svg",
          Longevity: "longevity.svg",
          Religion: "Religion.svg",
          Health: "health.svg",
          Medical: "Medical.svg",
          Taste: "taste.svg",
          None: "None.svg",
        },
      },
      {
        idx: 5,
        name: "Diet Conviction",
        prompt: "Why did you choose the food you ate for your last meal?",
        type: "Rank",
        fields: [
          "Taste/Flavor",
          "Habit",
          "Necessity",
          "Function",
          "Socializing",
          "Experiential",
        ],
        sprites: {
          "Taste/Flavor": "taste.svg",
          Habit: "Habit.svg",
          Necessity: "Necessity.svg",
          Function: "function.svg",
          Socializing: "Socializing.svg",
          Experiential: "Experiential.svg",
        },
      },
      {
        idx: 6,
        name: "Consumption Habits",
        prompt: "How often do you eat fruits and vegetables?",
        type: "Circle",
        fields: [
          "Every meal",
          "Once a day",
          "Multiple times a week",
          "Once a week",
          "Sometimes",
          "Never",
        ],
      },
      {
        idx: 7,
        name: "Consumption Habits",
        prompt: "How often do you eat red meat (mammals)",
        type: "Circle",
        fields: [
          "Every meal",
          "Once a day",
          "Multiple times a week",
          "Once a week",
          "Sometimes",
          "Never",
        ],
      },
      {
        idx: 8,
        name: "Consumption Habits",
        prompt: "What phrases are the most appealing to you?",
        type: "MarkRank",
        fields: [
          "Fat-free",
          "Sugar-free",
          "Gluten-free",
          "Fair-trade",
          "Organic",
          "Local",
          "Natural",
          "Low-calorie",
          "Full-fat, low-fat",
          "Pesticide-free",
          '"Certifed"',
          "Non-GMO",
          '"made with real fruit"',
          "Kosher/Hala",
          "Hormone-free",
        ],
      },
      {
        idx: 9,
        name: "Consumption Habits",
        prompt:
          "In the past week, where did you primarily get your meals from?",
        type: "Circle",
        fields: [
          "Homemade",
          "Fast food",
          "Restaurant",
          "Deli Premade",
          "Frozen Premade",
          "Meal Replacement",
        ],
        sprites: {
          Homemade: "Homemade.svg",
          "Fast food": "fastfood.svg",
          Restaurant: "Restaurant.svg",
          "Deli Premade": "GroceryPremade.svg",
          "Frozen Premade": "Frozen.svg",
          "Meal Replacement": "MealReplacement.svg",
        },
      },
      {
        idx: 10,
        name: "Consumption Habits",
        prompt: "How often do you drink alcohol?",
        type: "Circle",
        fields: [
          "Multiple drinks a day",
          "One drink a day",
          "Once a week",
          "Multiple times a week",
          "Sometimes",
          "Never",
        ],
      },
      {
        idx: 11,
        name: "Consumption Habits",
        prompt:
          "What type of alcohol do you usually drink? (in order of most often to least)",
        type: "MarkRank",
        fields: [
          "Wine",
          "Hard Liquor",
          "Spritzers",
          "Cocktails",
          "Beer",
          "Non-Drinker",
        ],
        sprites: {
          Wine: "Wine.svg",
          "Hard Liquor": "hard.svg",
          Spritzers: "Spritzer.svg",
          Cocktails: "cocktail.svg",
          Beer: "Beer.svg",
          "Non-Drinker": "NoAlc.svg",
        },
      },
      {
        idx: 12,
        name: "Body",
        prompt:
          "Do you have a diagnosed pre-existing physical illness or condition?",
        type: "Circle",
        fields: ["Yes", "No"],
      },
      {
        idx: 13,
        name: "Body",
        prompt:
          "How many hours of sleep do you get on a weekday, weekend, and last night? ",
        type: "Input",
        fields: ["Weekday", "Weekend", "Last Night"],
        inputType: "number",
      },
      {
        idx: 14,
        name: "Body",
        prompt: "What do you primarily do for exercise?",
        type: "Mark",
        fields: [
          "Cardio Exercise",
          "Strength Exercises",
          "Flexibility/Balance",
          "Team Sports",
          "Don't Exercise",
        ],
        sprites: {
          "Cardio Exercise": "Cardio.svg",
          "Strength Exercises": "Strength.svg",
          "Flexibility/Balance": "Flexibility.svg",
          "Team Sports": "Sport.svg",
          "Don't Exercise": "Nope.svg",
        },
      },
      {
        idx: 15,
        name: "Body",
        prompt: "How often do you engage in these exercises?",
        type: "Circle",
        fields: [
          "Never. Don't Exercise",
          "Occasionally",
          "1-2 Times a Month",
          "Weekly",
          "Multiple Times a Week",
          "Everyday",
          "Multiple Times a Day",
        ],
      },
      {
        idx: 16,
        name: "Perception",
        prompt: "Are you currently happy with the way you look physically?",
        type: "Circle",
        fields: ["Yes", "No"],
      },
      {
        idx: 17,
        name: "Perception",
        prompt:
          "If you could change one physical aesthetic part of yourself, what would you change?",
        type: "Circle",
        fields: [
          "Facial appearance",
          "Musculature",
          "Body Fat Percentage",
          "Height",
          "Proportions",
          "No change",
        ],
        sprites: {
          "Facial appearance": "Facial Appearance.svg",
          Musculature: "Musculature.svg",
          "Body Fat Percentage": "BodyFat.svg",
          Height: "Height.svg",
          Proportions: "Proportions.svg",
          "No change": "noChange.svg",
        },
      },
      {
        idx: 18,
        name: "Perception",
        prompt:
          "Do you believe that physical appearance matters in the real world?",
        type: "Circle",
        fields: ["No", "Kind of", "N/A", "Yes"],
        tooltips: {
          No: "No18",
          "Kind of": "Kind of18",
          "N/A": "N/A18",
          Yes: "Yes18",
        },
      },
    ],
  },
  {
    name: "EMOTIONAL",
    questions: [
      {
        idx: 19,
        name: "Feelings",
        prompt: "How are you feeling as of late?",
        type: "Mark",
        fields: [
          "Admiration",
          "Adoration",
          "Aesthetic appreciation",
          "Amusement",
          "Anger",
          "Anxiety",
          "Awe",
          "Awkwardness",
          "Boredom",
          "Calmness",
          "Confusion",
          "Craving",
          "Disgust",
          "Empathic pain",
          "Entrancement",
          "Excitement",
          "Fear",
          "Horror",
          "Interest",
          "Joy",
          "Nostalgia",
          "Relief",
          "Romance",
          "Sadness",
          "Satisfaction",
          "Sexual desire",
          "Surprise",
        ],
      },
      {
        idx: 20,
        name: "Attitude",
        prompt:
          "Which phrase is the closest to your attitude towards life right now?",
        type: "Circle",
        fields: [
          '"The best is yet to come"',
          '"If the opportunity doesn’t knock, build a door"',
          '"I don’t know where I’m going, but I’m on my way."',
          '"All good things come with a price."',
          '"The best way out is always through."',
          '"I know nothing of what is to come and I do not care."',
          '"I will always follow my heart."',
        ],
      },
      {
        idx: 21,
        name: "Autonomy",
        prompt:
          "Do you feel you have control over the path your life is taking?",
        type: "Circle",
        fields: [
          "Yes, I have control over my life",
          "Mostly, I think I have some control",
          "Kind of, I can influence but I cannot control",
          "No, the path of my life is unchangeable",
        ],
      },
      {
        idx: 22,
        name: "Happiness",
        prompt: "Which phrase is the closest to how you feel about happiness?",
        type: "Circle",
        fields: [
          "Happiness is a choice I make everyday",
          "Happiness is an emotion I don’t have control of",
          "Happiness is neither a choice nor an emotion I can control",
          "I am incapable of happiness",
        ],
      },
      {
        idx: 23,
        name: "Stress",
        prompt: "What do you do to manage stress?",
        type: "Mark",
        fields: [
          "Meditation",
          "Creative Activities",
          "Therapy",
          "Exercise",
          "Get away",
          "Work",
          "Substance Relief",
          "Food Relief",
          "Going to nature",
          "Intellectual Stimulation",
          "Socializing",
          "Sexual pleasure",
          "Prescribed Medication",
          "Emotional Relief",
        ],
        sprites: {
          Meditation: "Meditation.svg",
          "Creative Activities": "Creative.svg",
          Therapy: "Therapy.svg",
          Exercise: "Exercise.svg",
          "Get away": "GetAway.svg",
          Work: "Work.svg",
          "Substance Relief": "SubstanceRelief.svg",
          "Food Relief": "FoodRelief.svg",
          "Going to nature": "Nature.svg",
          "Intellectual Stimulation": "Intellectual.svg",
          Socializing: "Socializing.svg",
          "Sexual pleasure": "Sex.svg",
          "Prescribed Medication": "PrescribedMeds.svg",
          "Emotional Relief": "Catharsis.svg",
        },
      },
    ],
  },
  {
    name: "SPIRITUAL",
    questions: [
      {
        idx: 24,
        name: "Autonomy",
        prompt:
          "Do you feel your actions and opinions are valuable to the world?",
        type: "Circle",
        fields: [
          "Yes",
          "Mostly",
          "Kind of",
          "No",
          "I don't want to associate with the world.",
        ],
        tooltips: {
          No: "No24",
          "Kind of": "Kind of24",
          Mostly: "Mostly24",
          Yes: "Yes24",
        },
      },
      {
        idx: 25,
        name: "Belief",
        prompt:
          'Are you motivated by something other than making money? If yes, rank the choices below. Otherwise, select "no" and continue on',
        type: "MarkRank",
        fields: [
          "Curiosity",
          "Happiness",
          "Fufilling a role",
          "Learning",
          "Status",
          "Experiencing",
          "Religious Enlightenment",
          "No",
        ],
      },
      {
        idx: 26,
        name: "Belief",
        prompt: "How do you most align?",
        type: "Circle",
        fields: [
          "Theism",
          "Deism",
          "Agnosticism",
          "Atheism",
          "Animism",
          "Totemism",
        ],
      },
    ],
  },
  {
    name: "SOCIAL",
    questions: [
      {
        idx: 27,
        name: "Physical Touch",
        prompt: "How often do you get a good hug(s) from another person?",
        type: "Circle",
        fields: [
          "Everyday",
          "Multiple times a week",
          "Once a week",
          "A few times a month",
          "Once a month",
          "Once every few months",
        ],
      },
      {
        idx: 28,
        name: "Community",
        prompt: "What community or club do you most associate with?",
        type: "Circle",
        fields: [
          "Activity Community",
          "Spiritual Community",
          "Occupational Community",
          "Academic Community",
          "Familial Community",
          "Friend Community",
          "Local Community",
          "Fandom Community",
          "Creative Communities",
          "No Community",
        ],
        sprites: {
          "Activity Community": "Activity.svg",
          "Spiritual Community": "Spiritual.svg",
          "Occupational Community": "Occupational.svg",
          "Academic Community": "Academic.svg",
          "Familial Community": "Familial.svg",
          "Friend Community": "Friend.svg",
          "Local Community": "Local.svg",
          "Fandom Community": "FandomOnline.svg",
          "Creative Communities": "Creative.svg",
          "No Community": "NoCommunity.svg",
        },
      },
      {
        idx: 29,
        name: "Inner Circle",
        prompt:
          "How many individuals would you consider to be in your most intimate circle?",
        type: "Input",
        placeholder: "Number of individuals",
        inputType: "number",
      },
      {
        idx: 30,
        name: "Familial ties",
        prompt: "How often do you communicate with your biological family?",
        type: "Circle",
        fields: [
          "Never",
          "Everyday",
          "Once a week",
          "A few times a week",
          "A few times a month",
          "Once a month",
          "A few times a year",
          "Once a year",
          "A few times every 1-3 years",
        ],
      },
      {
        idx: 31,
        name: "Best Friend",
        prompt: "Do you have one or more best friends?",
        type: "Circle",
        fields: ["yes", "no"],
      },
      {
        idx: 32,
        name: "Best Friend",
        prompt: "How often do you interact with your best friend(s)?",
        type: "Circle",
        fields: [
          "Everyday",
          "Every few days",
          "Once a week",
          "Every few weeks",
          "Once a month",
          "N/A",
        ],
      },
      {
        idx: 33,
        name: "Best Friend",
        prompt:
          "How would you describe your relationship with your best friend?",
        type: "Circle",
        fields: [
          "Confidant",
          "Partner in Crime",
          "Collaborator",
          "Yin & Yang",
          "My Reflection",
          "Symbiotic",
          "Rollercoaster",
          "Ride or Die",
          "Safety Net",
        ],
        sprites: {
          Confidant: "Confidant.svg",
          "Partner in Crime": "PartnerinCrime.svg",
          Collaborator: "Collaborator.svg",
          "Yin & Yang": "YinYang.svg",
          "My Reflection": "Reflection.svg",
          Symbiotic: "Symbiotic.svg",
          Rollercoaster: "Rollercoaster.svg",
          "Ride or Die": "RideorDie.svg",
          "Safety Net": "SafetyNet.svg",
        },
      },
      {
        idx: 34,
        name: "Relationships",
        prompt: "Which relationship is the most supportive to you right now?",
        type: "Circle",
        fields: [
          "Parent(s)",
          "Partner",
          "Friend",
          "Siblings",
          "Co-worker/colleague",
          "Mentor",
          "Object",
          "Pet",
          "Other",
        ],
      },
    ],
  },
  {
    name: "WEB SOCIAL",
    questions: [
      {
        idx: 35,
        name: "Social Media",
        prompt:
          "How often, if at all, do you currently make social media posts?",
        type: "Circle",
        fields: [
          "Multiple times a day",
          "Once a day",
          "A few times a week",
          "Once a week",
          "A few times a month",
          "Never",
        ],
      },
      {
        idx: 36,
        name: "Social Media",
        prompt: "How regularly do you visit your social media?",
        type: "Circle",
        fields: [
          "Multiple times an hour",
          "Hourly",
          "Every few hours",
          "Everyday",
          "A few times a week",
          "A few times a month",
          "Never",
        ],
      },
      {
        idx: 37,
        name: "Social Media",
        prompt: "How do you feel after spending time on social media?",
        type: "Mark",
        fields: [
          "Admiration",
          "Adoration",
          "Aesthetic appreciation",
          "Amusement",
          "Anger",
          "Anxiety",
          "Awe",
          "Awkwardness",
          "Boredom",
          "Calmness",
          "Confusion",
          "Craving",
          "Disgust",
          "Empathic pain",
          "Entrancement",
          "Excitement",
          "Fear",
          "Horror",
          "Interest",
          "Joy",
          "Nostalgia",
          "Relief",
          "Romance",
          "Sadness",
          "Satisfaction",
          "Sexual desire",
          "Surprise",
        ],
      },
    ],
  },
  {
    name: "ENVIRONMENTAL",
    questions: [
      {
        idx: 38,
        name: "Immersion",
        prompt: "How often do you think you should be immersed in nature?",
        type: "Circle",
        fields: [
          "Everyday",
          "Multiple times a week",
          "Once a week",
          "A few times a month",
          "Once a month",
          "A few times a year",
          "Once a year",
          "Not neccesary",
        ],
      },
      {
        idx: 39,
        name: "Reality",
        prompt: "How often do you go outside (on a regular week)?",
        type: "Circle",
        fields: [
          "Hermit “Hikikomori”",
          "Commuting outside",
          "Indirect necessity",
          "Taking the scenic route",
          "Frequently outdoors",
          "Nature-lover",
        ],
        sprites: {
          "Hermit “Hikikomori”": "Hermit.svg",
          "Commuting outside": "Commuting.svg",
          "Indirect necessity": "IndirectNecessity.svg",
          "Taking the scenic route": "ScenicRoute.svg",
          "Frequently outdoors": "FrequentlyOutdoors.svg",
          "Nature-lover": "NatureLover.svg",
        },
      },
      {
        idx: 40,
        name: "Sustainability",
        prompt: "Which of these sustainable activities do you do? ",
        type: "Mark",
        fields: [
          "Recycling",
          "Carpooling",
          "Sustainable vehicles",
          "Public transportation",
          "Buying sustainable goods",
          "Not littering",
          "Being mindful",
          "Encouraging others to be mindful",
          "Donating financially/volunteering to causes",
          "Educating yourself",
          "Gardening",
          "None",
        ],
        sprites: {
          Recycling: "Recycle.svg",
          Carpooling: "PeopleinCar.svg",
          "Sustainable vehicles": "SmartCar.svg",
          "Public transportation": "Subway.svg",
          "Buying sustainable goods": "EcoGoods.svg",
          "Not littering": "NotLittering.svg",
          "Being mindful": "GlobalMindset.svg",
          "Encouraging others to be mindful": "EcoEvangelist.svg",
          "Donating financially/volunteering to causes": "Donation.svg",
          "Educating yourself": "Education.svg",
          Gardening: "Gardening.svg",
          None: "None.svg",
        },
      },
    ],
  },
  {
    name: "VOCATIONAL",
    questions: [
      {
        idx: 41,
        name: "Job",
        prompt: "Do you have a paid job?",
        type: "Circle",
        fields: ["yes", "no"],
      },
      {
        idx: 42,
        name: "Industry",
        prompt:
          "What industry does your job fall under? If there is no job, what industry would you want?",
        type: "Input",
        placeholder: "Industries",
        inputType: "text",
        autocomplete: "industries",
        multiple: true,
      },
      {
        idx: 43,
        name: "Fufillment",
        prompt:
          "Is your job challenging/stimulating/fulfilling, if so, in what way?",
        type: "MarkRank",
        fields: [
          "Not stimulating",
          "Intellectually",
          "Financially",
          "Creatively",
          "Socially",
          "Mindlessly",
          "Emotionally",
          "Spiritually",
          "Prestige",
          "Character & Personal Development",
        ],
      },
      {
        idx: 44,
        name: "Work Values",
        prompt: "What do you most value in a job?",
        type: "Rank",
        fields: [
          "Office Culture",
          "Learning",
          "Pay/Benefits",
          "Career growth",
          "Location",
          "Creative stimulation",
          "Prestige/Status",
          "Convenience",
          "Character & Personal Development",
        ],
      },
    ],
  },
  {
    name: "FINANCIAL",
    questions: [
      {
        idx: 45,
        name: "Financial Happiness",
        prompt: "Do you feel you make enough money to pursue happiness?",
        type: "Circle",
        fields: ["yes", "no"],
      },
      {
        idx: 46,
        name: "Financial Independence",
        prompt:
          "Are you financially independent; which range applies most to you?",
        type: "Circle",
        fields: ["Yes", "Mostly", "Kind of", "Not Really", "Nope :)"],
        tooltips: {
          Yes: "Yes46",
          Mostly: "Mostly46",
          "Kind of": "Kind of46",
          "Not Really": "Not Really46",
          "Nope :)": "Nope46",
        },
      },
      {
        idx: 47,
        name: "Spending",
        prompt: "In your perfect world, how do you want to spend your money?",
        type: "Percentage",
        fields: [
          "Food",
          "Bills & Utilities",
          "Rent/Mortgage",
          "Creative/Intellectual Endeavors",
          "Health",
          "Savings",
          "Donation",
          "Investing",
          "Socializing",
          "Emergency fund",
          "Entertainment",
          "Quality Goods",
          "Nonessential Goods",
        ],
      },

      {
        idx: 48,
        name: "Spending",
        prompt: "How do you actually spend your money?",
        type: "Percentage",
        fields: [
          "Food",
          "Bills & Utilities",
          "Rent/Mortgage",
          "Creative/Intellectual Endeavors",
          "Health",
          "Savings",
          "Donation",
          "Investing",
          "Socializing",
          "Emergency fund",
          "Entertainment",
          "Quality Goods",
          "Nonessential Goods",
        ],
      },
    ],
  },
  {
    name: "INTELLECTUAL",
    questions: [
      {
        idx: 49,
        name: "Learning",
        prompt:
          "On your off time, would you prefer to learn or chill? Chill is literally anything else.",
        type: "Circle",
        fields: ["Learn", "Chill"],
      },
      {
        idx: 50,
        name: "Intelligence",
        prompt:
          "What kind of intelligence do you believe you practice the most?",
        type: "Circle",
        fields: [
          "Naturalist",
          "Musical",
          "Logical-Mathematical",
          "Existential",
          "Interpersonal",
          "Bodily-kinesthetic",
          "Linguistic",
          "Intra-personal",
          "Spatial",
        ],
      },
      {
        idx: 51,
        name: "Intelligence",
        prompt:
          "If you could improve one cognitive function which would it be?",
        type: "Circle",
        fields: [
          "Language",
          "Math/Logic",
          "Memory",
          "Creativity",
          "Patterns recognition",
          "Multitasking",
          "Problem Solving",
          "Focus",
        ],
      },
    ],
  },
];
