export const questionaireProgress = {
  SET_SECTION: "SET_SECTION",
  SET_QUESTION: "SET_QUESTION",
  SET_PROGRESS: "SET_PROGRESS",
};

export const hoverInfoActions = {
  CLEAR: "CLEAR",
  POP: "POP",
  SET: "SET",
  APPEND: "APPEND",
};

export const quizActions = {
  DONE: "DONE",
  RESET: "RESET",
};

export const landingActions = {
  SELECT_DIMENSION: "SELECT_DIMENSION",
};

export const researchActions = {
  SET_TAB: "SET_TAB",
};

export const setSection = (idx) => ({
  type: questionaireProgress.SET_SECTION,
  idx,
});

export const setQuestion = (idx) => ({
  type: questionaireProgress.SET_QUESTION,
  idx,
});

export const setProgress = (section, question) => ({
  type: questionaireProgress.SET_PROGRESS,
  section,
  question,
});

export const clearInfo = () => ({
  type: hoverInfoActions.CLEAR,
});

export const popInfo = () => ({
  type: hoverInfoActions.POP,
});

export const setInfo = (tip) => ({
  type: hoverInfoActions.SET,
  tip,
});

export const appendInfo = (tip) => ({
  type: hoverInfoActions.APPEND,
  tip,
});

export const setQuizDone = () => ({
  type: quizActions.DONE,
});

export const resetQuiz = () => ({
  type: quizActions.RESET,
});

export const setSelection = (d) => ({
  type: landingActions.SELECT_DIMENSION,
  dimension: d,
});

export const setTab = (tab) => ({
  type: researchActions.SET_TAB,
  tab,
});
