import React from "react";

function BackButton(props) {
  return (
    <div className="back-button invert-color standard-border">
      <svg
        className="bi bi-chevron-double-left"
        width="3em"
        height="3em"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M8.354 1.646a.5.5 0 010 .708L2.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M12.354 1.646a.5.5 0 010 .708L6.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}

export default BackButton;
