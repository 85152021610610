import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../css/playboard.css";
import PlayboardElement from "./PlayboardElement";

// have a list of svg
// put a framer div around them
// on hover, start an animation at random, could use variants

const secondRowTop = 45;
const firstRowTop = 15;
const defaultWidth = "30%";
const defaultLeft = 30;
const halfDefaultWidth = "15%";

const shapes = [
  //squares, layer 1
  {
    z: 1,
    left: 5,
    top: firstRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><rect id="_1R" data-name="1R" width="214" height="214" fill="#00953b"/></svg>',
  },
  {
    z: 1,
    left: 5 + defaultLeft * 1,
    top: firstRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><rect id="_2R" data-name="2R" width="214" height="214" fill="#063748"/></svg>',
  },
  {
    z: 1,
    left: 5 + defaultLeft * 2,
    top: firstRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><rect id="_3R" data-name="3R" width="214" height="214" fill="#835b55"/></svg>',
  },

  {
    z: 1,
    left: 50,
    top: secondRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><rect id="_4R" data-name="4R" width="214" height="214" fill="#01a0fb"/></svg',
  },

  // circles, layer 2
  {
    z: 2,
    left: 5 + defaultLeft * 1,
    top: firstRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><circle id="_5E" data-name="5E" cx="107" cy="107" r="107" fill="#36b2b4"/></svg>',
  },
  {
    z: 2,
    left: 5 + defaultLeft * 2,
    top: firstRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><circle id="_6E" data-name="6E" cx="107" cy="107" r="107" fill="#af4697"/></svg>',
  },
  {
    z: 2,
    left: 5 + defaultLeft * 1.5,
    top: secondRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><circle id="_7E" data-name="7E" cx="107" cy="107" r="107" fill="#ff9a66"/></svg>',
  },

  //layer 3
  {
    z: 3,
    left: 5 + defaultLeft * 1,
    top: firstRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 213.004 214"><path id="_8L" data-name="8L" d="M322.5,576.5v107h-107v107h213v-214Z" transform="translate(-215.5 -576.5)" fill="#c3dcbf"/></svg>',
  },
  {
    z: 3,
    left: 5 + defaultLeft * 2.5,
    top: firstRowTop,
    width: halfDefaultWidth,
    svg:
      '<svg viewBox="0 0 107 214"><rect id="_9R" data-name="9R" width="107" height="214" fill="#137a79"/></svg>',
  },
  {
    z: 3,
    left: 5 + defaultLeft * 0.5,
    top: secondRowTop,
    width: defaultWidth,
    svg:
      '<svg viewBox="0 0 214 214"><rect id="_10R" data-name="10R" width="214" height="214" fill="#5bae40"/></svg>',
  },

  // layer 4
  {
    z: 4,
    left: 5 + defaultLeft * 0.5,
    top: secondRowTop,
    width: halfDefaultWidth,
    svg:
      '<svg viewBox="0 0 107 214"><rect id="_11R" data-name="11R" width="107" height="214" fill="#137a79"/></svg>',
  },
  //smol circle
  {
    z: 5,
    left: 5 + defaultLeft * 0.5,
    top: 60,
    width: halfDefaultWidth,
    svg:
      '<svg viewBox="0 0 105.615 106.014"><rect id="_12E" data-name="12E" width="106.014" height="105.615" rx="52.807" transform="translate(105.615) rotate(90)" fill="#ff8273"/> </svg>',
  },
];

function Playbox(props) {
  const svgs = _.map(shapes, (shape) => (
    <PlayboardElement
      shape={shape}
      key={_.join(["playboard", shape.svg], "-")}
    />
  ));
  return (
    <div className="playboard">
      {svgs}

      <Row className="hidden-titlebox standard-border">
        <Col className="hidden-title" md={9}>
          <h2>Learning how to be healthy should not be this hard</h2>
        </Col>
        <Col md={3} className="circle-button-wrapper">
          <Link to="/quiz" target="_blank">
            <div className="circle-button standard-border invert-color">
              <h3>learn how.</h3>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default Playbox;
