import React from "react";
import "../../css/question.css";
import { getAnswer } from "../../matrices/answerMatrix.js";
import {
  nextHandler,
  removeEnterKeyListener,
  setEnterKeyListener,
} from "./questionUtils";

// props:
// sectionIdx;
// questionIdx;

class Question extends React.Component {
  constructor(props) {
    super(props);
    const storedAnswer = getAnswer(
      this.props.sectionIdx,
      this.props.questionIdx
    );
    const env = process.env.NODE_ENV || "development";

    this.state = {
      answer: storedAnswer,
      shouldDisplayNext:
        storedAnswer !== null ||
        env === "development" ||
        process.env.REACT_APP_PREVIEW,
    };
    this.enterKeyListenerCallback = this.enterKeyListenerCallback.bind(this);
  }

  componentDidMount() {
    this.props.setInfo(this.props.question.type);
    if (this.state.shouldDisplayNext)
      // only set this in componentDidMount if the answer is available
      setEnterKeyListener(this.enterKeyListenerCallback);
  }

  componentWillUnmount() {
    removeEnterKeyListener(this.enterKeyListenerCallback);
  }

  enterKeyListenerCallback(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      nextHandler(this.props);
    }
  }
}

export default Question;
