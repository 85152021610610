import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../../css/navbar.css";

const navs = {
  HOME: "/",
  QUIZ: "/quiz",
  "DATA VISUALIZATION": "/data",
  "RESEARCH <br/> & <br/> DEVELOPMENT": "/rd",
  STORY: "/story",
};

function generateNavs() {
  return _.map(navs, (link, nav) => {
    var target = "";
    if (nav === "QUIZ") target = "_blank";

    return (
      <Col className="standard-border" style={{ height: "11.66vw" }} key={nav}>
        <NavLink
          exact
          to={link}
          activeClassName="navbar-active"
          target={target}
          className="navbar-member"
        >
          <h5 dangerouslySetInnerHTML={{ __html: nav }} />
        </NavLink>
      </Col>
    );
  });
}

function NavBar() {
  return <Row noGutters={true}>{generateNavs()}</Row>;
}

export default NavBar;
