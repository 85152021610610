import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  appendInfo,
  popInfo,
  setInfo,
  setQuestion,
  setQuizDone,
  setSection,
} from "../../redux/actions";
import Question from "./Question";
import {
  answerChangeHandler,
  generateFields,
  generateHeader,
  getSprite,
  removeEnterKeyListener,
  setEnterKeyListener,
} from "./questionUtils.js";

// props:
// sectionIdx;
// questionIdx;

// mark style questions have a list of fields

class MarkCircle extends Question {
  constructor(props) {
    super(props);
    this.state.currentField = null;
  }

  genRow(fields, state) {
    var cols = _.map(fields, (field) => {
      const markButtonCol = classNames("mark-button-col", "standard-border", {
        highlight: field === state.currentField,
      });

      var sprite;
      if (this.props.question.sprites) sprite = getSprite(this.props, field);

      return (
        <Col
          key={field}
          md={4}
          className={markButtonCol}
          onMouseEnter={() => this.props.appendInfo(field)}
          onMouseLeave={() => this.props.popInfo()}
          style={
            state.answer && state.answer[field]
              ? {
                  backgroundColor: this.props.question.subFields[
                    state.answer[field]
                  ],
                }
              : {}
          }
        >
          <div
            id={field}
            className="mark-button mark-button-mark-circle"
            onClick={() => this.handleSelection(field)}
          >
            <p className="noselect"> {field}</p>
            {this.props.question.sprites ? (
              <div
                className="sprite sprite-sm"
                style={{
                  content: sprite,
                }}
              />
            ) : null}
          </div>
        </Col>
      );
    });

    return <Row>{cols}</Row>;
  }

  generateButton(field, svg) {}

  getPaddingSize(numFields) {
    if (numFields <= 3) {
      return 3;
    }
    return 1;
  }

  handleSelection(field) {
    var newState = this.state;
    if (this.state.answer && this.state.answer[field]) {
      newState.answer[field] = null;
    }
    newState.currentField = field;
    this.setState(newState);
  }

  generateSubfields() {
    return _.map(Object.keys(this.props.question.subFields), (subField) => {
      return (
        <Row
          className="flex-grow-1 d-flex flex-column standard-border justify-content-center invert-color"
          style={{ textAlign: "left" }}
          onClick={() => this.handleSubfieldSelection(subField)}
        >
          <p style={{ paddingLeft: "10%" }}>{subField}</p>
        </Row>
      );
    });
  }

  handleSubfieldSelection(subfield) {
    var answer = this.state.answer || {};
    answer[this.state.currentField] = subfield;
    this.setState({ answer });
  }

  componentDidUpdate() {
    console.log(this.state.shouldDisplayNext);
    if (
      !this.state.shouldDisplayNext &&
      this.state.answer &&
      _.reduce(this.state.answer, (aggr, val, key) => aggr && val, true)
    )
      this.setState({ shouldDisplayNext: true });
    else if (this.state.shouldDisplayNext && !this.state.answer)
      this.setState({ shouldDisplayNext: false });

    answerChangeHandler(this.props, this.state.answer);

    if (this.state.shouldDisplayNext)
      setEnterKeyListener(this.enterKeyListenerCallback);
    else removeEnterKeyListener(this.enterKeyListenerCallback);
  }

  render() {
    const fieldsPerRow = 3;

    return (
      <div>
        <div>{generateHeader(this.props, this.state)}</div>
        <Container className="answer-box ">
          <Row noGutters={true}>
            <Col md={7}>
              {generateFields(
                this.props,
                this.state,
                this.genRow.bind(this),
                fieldsPerRow
              )}
            </Col>
            <Col md={1}></Col>
            <Col
              md={4}
              className="d-flex flex-column"
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              {this.state.currentField ? this.generateSubfields() : <div></div>}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(null, {
  setSection,
  setQuestion,
  setInfo,
  appendInfo,
  popInfo,
  setQuizDone,
})(MarkCircle);
