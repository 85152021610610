import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../css/titlebox.css";
import BackButton from "./BackButton";

function TitleBox(props) {
  const { customTitle, customButtonText, linkMeta } = props;

  const linkTo = Object.assign({ pathname: "/quiz" }, linkMeta);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  return (
    <div className="title-box standard-border d-flex flex-column">
      {props.backButton ? (
        <Row>
          <Col lg={3} md={4}>
            <Link to="/">
              <BackButton />
            </Link>
          </Col>
          <Col lg={9} md={8} />
        </Row>
      ) : (
        <div />
      )}

      <Row>
        <Col lg={9}>
          <h1 className="title text-left">
            {customTitle ? customTitle : "How Healthy Are We?"}
          </h1>
        </Col>
        {!isTabletOrMobileDevice ? (
          <div className="logo">
            <Image alt="logo" fluid />
          </div>
        ) : null}
      </Row>
      <Row className="title-box-button-row flex-grow-1">
        <Col />
        <Col lg={4} md={6} s={8} xs={4}>
          <Link to={linkTo}>
            <div className="hhaw-button standard-border invert-color">
              {customButtonText ? customButtonText : "find out"}
            </div>
          </Link>
        </Col>
        <Col />
      </Row>
    </div>
  );
}

export default TitleBox;
