import React from "react";
import "../../css/playboard.css";
import _ from "lodash";
import { motion, useAnimation, useMotionValue } from "framer-motion";

const playBoardAnimations = [
  { x: 0, y: -20 },
  { x: 20, y: 0 },
  { x: 0, y: 20 },
  { x: -20, y: 0 },
];

function svgOnHoverStart(controls, x, y) {
  //   var movement = _.sample(validAnimations(x.get(), y.get()));
  var movement = _.sample(playBoardAnimations);

  controls.start({
    left: movement.x + x.get() + "%",
    top: movement.y + y.get() + "%",
  });

  x.set(x.get() + movement.x);
  y.set(y.get() + movement.y);
}

function PlayboardElement(props) {
  const { z, left, top, width, svg } = props.shape;

  const controls = useAnimation();
  const x = useMotionValue(left);
  const y = useMotionValue(top);
  return (
    <motion.div
      style={{
        // height,
        width,
        zIndex: z,
        left: left + "%",
        top: top + "%",
        position: "absolute",
      }}
      animate={controls}
      onHoverStart={(e, info) => svgOnHoverStart(controls, x, y)}
      dangerouslySetInnerHTML={{ __html: svg }}
    ></motion.div>
  );
}

export default PlayboardElement;
