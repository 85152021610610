const colors = [
  "#00953B",
  "#E8392E",
  "#52AF78",
  "#FD5D07",
  "#F6ADC4",
  "#DDE047",
  "#0768DB",
  "#36B2B4",
  "#C3DCBF",
  "#66CCFF",
  "#F0B41E",
  "#F8C7B8",
  "#C3DBBF",
  "#CC6600",
  "#835B55",
  "#E1CEAE",
  "#52AF78",
  "#EC8BA0",
  "#FF9967",
  "#FF8273",
  "#5BAE40",
  "#3B4696",
  "#B9282F",
  "#52522E",
  "#A2D3E1",
  "#D01A4B",
  "#FFD101",
];

export { colors };
