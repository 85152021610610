import "circular-std";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DataViz from "./components/DataViz";
import Landing from "./components/Landing";
import Quiz from "./components/Quiz";
import Research from "./components/Research";
import Story from "./components/Story";
import "./css/index.css";

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/quiz">
          <Quiz />
        </Route>
        <Route path="/data">
          <DataViz />
        </Route>
        <Route path="/rd">
          <Research />
        </Route>
        <Route path="/story">
          <Story />
        </Route>
      </Switch>
    </Router>
  </Provider>
);

export default Root;
