// case matters!,
export default {
  BIO: "These are some bio questions so that data collected has a baseline.",
  Input: "Fill out the field(s) below",
  Mark: "Mark all that apply!",
  Circle: "Choose one option.",
  Rank: "Rank all of the options, 1 being the highest priority.",
  MarkRank: "Rank the options, 1 being the highest priority.",
  MarkCircle:
    "For each option on the left, select the appropriate response in the column that will appear on the right",
  Percentage:
    "Think in terms of 100%, Where do you think/where do you want your money to be going to? We will do balancing for you!",
  Industry: "Mark one or more industries that apply to your occupation",
  Durability: "Do you reach for products with a long shelf life?",
  "Building Muscle":
    "Eat things that will help you gain muscle. (i.e. protein bar)",
  Medical:
    "Eat in consideration of medical conditions. (i.e. peanut allergies)",
  Health: "Eat foods in consideration of health. (i.e no sugar)",
  Urban: "City",
  Suburban:
    "Suburb. Outside of a city, but still near the urban metropolitan area. With some nature.",
  Rural: "More nature than city.",
  Brand:
    "Do you reach for brands you trust or have heard of before?  Do you like brand name versions of foods at the grocery store?",
  Quality:
    "Do you look for high quality foods in terms of size, shape, color, gloss, consistency, texture, flavour, etc.?",
  Price:
    "How important is it to find a low price on your groceries? Does a low price outweigh the prospect of better quality/healthier/more sustainably sourced food?",
  Convenience:
    "Do you like to buy prepackaged or pre-portioned meals? What about frozen dinners or instant food?",
  // Sustainability:
  //   "Is it important to you that your food be sustainable? Do you eat vegetarian or vegan food to reduce your carbon footprint?  Do you eat locally sourced food?",
  Versatility:
    "Do you buy ingredients that can be made into lots of different meals? Do you buy things like coffee for their function?",
  Perishability:
    "Is it important that your ingredients have a long shelf life?  Do you like fresh food that may not have a very long shelf-life?",
  Aesthetic: "Is it important for you to buy items with beautiful packaging?",
  "Trend/Hype":
    "Do you buy certain foods because they are trendy? E.g. Kale, acai berries, goji berries, macha powder, ancient grains, plant based meats, etc.)",
  Alcohol: "Beer, wine, spirts",
  Marijuana: "Smoking, consuming edibles, vaping THC oil, etc.",
  Opioids:
    "opium, heroin, morphine, legally or illegally obtained prescription pain pills such as OxyContin or Vicodin, etc.",
  Hallucinogens: "LSD, Psilocybin (magic mushrooms), etc.",
  Nicotine:
    "Smoking cigarettes/cigars, chewing tobacco, smoking cessation products such as nicotine patches or gum, etc.",
  Stimulants:
    "caffeine, Adderall, Ritalin, cocaine, methamphetamines, ecstasy, etc.",
  Depressants:
    'benzodiazepines or "benzos" such as Xanax, Klonopin, Ativan, Valium etc',
  Dissociative: "PCP, Ketamine, DMT, Salvia divinorum, etc.",
  Inhalants:
    "Inhaling glue, paint thinner, dry cleaning fluids, gasoline, felt-tip marker fluid, hair spray, deodorants, spray paint, whipped cream dispensers (whippets), etc.",
  "Prescribed Medication": "Any medication prescribed to you by a doctor",

  "Diet & Nutrition": "Eating well balanced, well proportioned meals",
  Sleep:
    "Making sure you practice good sleep hygiene: sleeping 7-9 hours with a consistent sleep schedule",
  "Physical Health (body)":
    "Making sure to get lots of physical activity, maintaining a healthy body weight.",
  "Physical Beauty":
    "Having a beautiful physical appearance-- whatever that means to you!",
  "Happiness (equanimity)":
    "Taking care of your mental health and practicing healthy coping skills in order to regularly experience happiness, calmness, and a strong resolve even when life is hard.",
  "Emotional awareness":
    "Understanding your emotions, how to healthily process them, and knowing when your emotions are negatively impacting your life",
  "Self Care":
    "Treating yourself, remembering to take breaks and rewarding yourself for hard work.",
  "Drugs + Alcohol*": "Using or consuming safe amounts of drugs or alcohol.",
  Purpose:
    "Having motivations that guide your actions. E.g. Helping people, wanting to make the world a better place, etc.",
  "Strong Values":
    "Having principles or standards that guide your actions; good judgement of what is important in life.",
  "Social Interaction":
    "Being social!  Talking to and spending time with other people.",
  Relationships:
    "Maintaining strong relationships with family, friends, romantic partners, etc.",
  "Support System (social)":
    "Having a network of people who you can rely on (usually emotionally).",
  Community: "Feeling like you belong to a community: religious, social, etc.",
  Popularity:
    "Being popular/liked by lots of people/ having a well maintained online platform.",
  "Our Immediate Environment":
    "Living in a nice home, nice area, surrounding yourself with kind people, etc.",
  "Exposure to Nature": "Regularly immursing yourself in nature.",
  Sustainability:
    "Allowing environmental consciousness to guide your actions; reducing your carbon footprint by limiting use of fossil fules and by not supporting industries that are large contributers to polution, etc.",
  Fulfillment:
    "Living a satisfying life; self-actualizing or becoming the best version of yourself",
  "Financial Management": "Wisely and healthily managing your money.",
  "Financial means (money)": "Having lots of money.",
  "Creativity/ Self Expression":
    "Practicing art that allows you to express your feelings or what it means to be you.",
  "Learning new things":
    "Reading about a subject that interests you, consuming educational content online, taking classes, etc.",
  "Mental & Creative Stimulation":
    "Learning new things, training new skills, thinking critically.",
  "Government Sourced Information":
    "Information put out by government-run organizations.",
  News: "Health Web Articles, TV, Radio, etc.",
  "Social Media": "Facebook, Twitter, Instagram, Reddit, etc.",
  Blogs: "Independent Individuals or Small Organizations",
  "Word of mouth": "from friends and family",
  Forums: "Discussions",
  Academia: "School",
  "Personal Experience": "You just know",
  Longevity: "Living as long and healthy as possible",
  // Health: "Sugar",
  // Medical: "Allergies or medical condition",
  "Culinary Experience": "Taste",
  None: "Eat Whatever",
  "Taste / Flavor": "Because you enjoy the taste of something",
  Habit: "Eating out of boredom/habit",
  Necessity: "Eating out of hunger",
  Function: "Nutritional value, drinking coffee for the caffeine",
  Socializing: "Some people drink alcohol to socialize",
  Experiential: "New trendy ramen place, best in the city",
  Homemade:
    "Food made at home. Grocery delivery and services like Blue Apron count!",
  "Fast food":
    "Food that is made quickly, designed to be eaten quickly.  If it is served in a restaurant that does not use waitstaff, it’s fast food.",
  Restaurant:
    "Food purchased at restaurants (not fast food) that can be eaten there or taken to go.",
  "Grocery Premade":
    "Meals sold at grocery stores that come premade/preassembled.  They may require taking different ingredients out of packaging or have you use a microwave.",
  "Frozen Premade":
    "Frozen meals sold in grocery stores that require a microwave to heat before eating.",
  "Meal Replacement Products":
    "E.g. Soylent, protein bars, meal replacement drinks.",

  Wine:
    "An alcoholic drink made from fermented grape juice. E.g. Red wine, white wine, rose, etc.",
  "Hard Liquor":
    "Spirits by themselves; not a cocktail.  Vodka, whisky, tequila, brandy, etc.",
  Spritzers: "White wine and soda water.  E.g. White Claw",
  Cocktails:
    "Alcoholic drinks with liquor(s) mixed with juices, sodas and/or other ingredients",
  Beer: "An alcoholic drink made from yeast-fermented malt flavored with hops.",

  "Cardio Exercise": "Walking, Swimming, Biking, Jogging, Running",
  "Strength Exercises":
    "Weights, Discus, Resistance Machines, pilates resistance",
  "Flexibility/Balance": "Yoga, Tai Chi, Pilates",
  "Team Sports":
    "Dance, Basketball, soccer (football), football, baseball, tennis, etc.",

  "Facial appearance": "The way your face or a certain part of your face looks",
  Musculature: "How much or how little muscular definition you have.",
  "Body Fat Percentage": "Your weight or percentage of fat on your body.",
  Height: "How short or tall you are.",
  Proportions:
    "What you body type is like.  E.g. short torso, long legs, broad shoulders, strong jaw, etc.",
  "No change": "There isn’t anything you want to change about your body.",

  No18:
    "People are able to look past appearances and can judge based on character, actions, and capabilities.",
  "Kind of18":
    "People are aware of physical appearance bias, but will still subconsciously treat you differently based on how you look.",
  "N/A18":
    "Despite subconscious and conscious bias, people can get around appearances through utilization of tools such as; fashion, makeup, accessories, and devices (glasses).",
  Yes18:
    "Not only does dressing appropriately matter, but physical appearance can often explicitly dictate how people will treat you.",

  Admiration: "A feeling of respect and warm approval.",
  Adoration: "A feeling of deep love and respect; worship; veneration.",
  "Aesthetic Appreciation":
    "A feeling of admiration of beauty, such as valuing the fine arts of music, literature, dance, visual art, the human body, etc.",
  Amusement: "The state or experiences of finding something funny",
  Anger: "A strong feeling of annoyance, displeasure, or hostility.",
  Anxiety:
    "A feeling of worry, nervousness, or unease, typically about an imminent event or something with an uncertain outcome.",
  Awe: "A feeling of reverential respect mixed with fear or wonder.",
  Awkwardness: "Causing or feeling embarrassment or inconvenience",
  Boredom:
    "Feeling weary because one is unoccupied or lacks interest in one's current activity.",
  Calmness:
    "Not showing or feeling nervousness, anger, or other strong emotions.",
  Confusion:
    "The state of being bewildered or unclear in one’s mind about something.",
  Craving: "Having a powerful desire for something.",
  Disgust:
    "A feeling of revulsion or strong disapproval aroused by something unpleasant or offensive.",
  "Empathic pain":
    "The feeling of recognizing and understanding another person's pain.",
  Entrancement:
    "A feeling of delight at being filled with wonder and enchantment.",
  Excitement: "A feeling of great enthusiasm and eagerness.",
  Fear:
    "An unpleasant emotion caused by the belief that someone or something is dangerous, likely to cause pain, or a threat.",
  Horror: "An intense feeling of fear, shock, or disgust.",
  Interest: "The state of wanting to know or learn about something or someone.",
  Joy: "A feeling of great pleasure and happiness.",
  Nostalgia:
    "A sentimental longing or wistful affection for the past, typically for a period or place with happy personal associations.",
  Relief:
    "A feeling of reassurance and relaxation following release from anxiety or distress.",
  Romance: "A feeling of excitement and mystery associated with love.",
  Sadness: "Feeling sorrow or regret.",
  Satisfaction:
    "Fulfillment of one's wishes, expectations, or needs, or the pleasure derived from this.",
  "Sexual desire":
    "The motivational state and an interest in sexual objects or activities, or as a wish, or drive to seek out sexual objects or to engage in sexual activities.",
  Surprise:
    "A feeling brought on by the discovery of an unexpected or astonishing event, fact, or thing.",

  Meditation: "Quiet, thoughtful reflection and focusing on breathing",
  "Creative Activities": "Drawing, singing, dancing",
  Therapy: "Sessions with a counselor or psychologist",
  Exercise: "Going for a run, working out at the gym, etc.",
  "Get away":
    "Aimless exploration or vacation; physically getting away from things that cause you stress",
  Work:
    "Distracting yourself with work for your job or housework (cleaning, etc.)",
  "Substance Relief": "(Cigarettes, weed, etc.,)",
  "Food Relief": "Distracting yourself by eating or drinking something tasty",
  "Going to nature": "Taking a walk in a park, sitting by a lake, etc.",
  "Intellectual Stimulation": "Puzzles, games, reading, music, etc.",
  // Socializing: "Talking to friends and family, in person or over the phone",
  Shopping: "Buying something new, in a mall, online, etc.",
  "Sexual pleasure": "Sex, partnered or otherwise.",
  "Prescribed medication": "Xanax, anxiety medication, etc.",
  "Emotional relief": "Catharsis, crying, screaming, venting (solo).",

  Yes24: "My perspective and contributions can change the world",
  Mostly24: "Although I can contribute value, I do not have much influence",
  "Kind of24":
    "I have little influence and my contributions are not very impactful.",
  No24: "I have no influence and no worthy contributions",

  Curiosity: "I am intrigued by what I do not already understand.",
  Happiness: "I want to do things that bring me joy.",
  "Fulfilling a role": "I want to feel useful.",
  "Learning (intellectual)": "I want to learn about and how to do new things.",
  Status: "I want to be in a powerful position",
  Experiencing: "I want to have meaningful experiences",
  "Religious enlightenment":
    "I want to be spiritually insightful, to learn to better practice my faith",

  Theism: "Believes in God(s) and religion",
  Deism: "Believes in God(s), not religion",
  Agnosticism: "Believes in the possibility of God(s)",
  Atheism: "Does not believe in God(s), nor religion",
  Animism: "Does beleive in spirits that reside in objects and living things",
  Totemism:
    "Has a personal connection with a spirit-being, such as an animal or plant",

  "Activity Community": "Gym/Gaming/improv",
  "Spiritual Community": "Belief",
  "Occupational community": "Work",
  "Academic Community": "School",
  "Familial Community": "Family",
  "Friend Community": "Friends",
  "Local Community": "Location/proximity",
  "Fandom Community":
    "Shared interests, majority of communications are limited to digital",
  "Creative Communities": "Developing physical art,  something tangible",
  "Occupational Community": "Union, co-workers",

  Confidant: "Tell them everything.",
  "Partner in Crime": "Personal cheerleader.",
  Collaborator: "We help each other grow and explore.",
  "Yin & Yang": "We are different people, but harmonize.",
  "My Reflection": "We think the same way.",
  Symbiotic: "I don't know what I do without them.",
  Rollercoaster: "Argue but always make up.",
  "Ride or Die": "Willing to do anything for them, even in the face of danger.",
  "Safety Net":
    "Time may have passed since we last spoke, but nothing will change us.",

  Yes46: "You 100% support yourself",
  Mostly46: "You rely on someone else 25% of the time, 75% you're on your own",
  "Kind of46": "You evenly split your expenses with someone else, 50-50",
  "Not Really46":
    "You rely on someone else 75% of the time, 25% of the time you're on your own",
  Nope46: "You completely rely on someone else financially",

  // Amusement: "The state or experiences of finding something funny",
  // Anger: "A strong feeling of annoyance, displeasure, or hostility.",
  // Anxiety:
  //   "A feeling of worry, nervousness, or unease, typically about an imminent event or something with an uncertain outcome.",
  // Awe: "A feeling of reverential respect mixed with fear or wonder.",
  // Awkwardness: "Causing or feeling embarrassment or inconvenience",
  // Boredom:
  //   "Feeling weary because one is unoccupied or lacks interest in one's current activity.",
  // Calmness:
  //   "Not showing or feeling nervousness, anger, or other strong emotions.",
  // Confusion:
  //   "The state of being bewildered or unclear in one’s mind about something.",
  // Craving: "Having a powerful desire for something.",
  // Disgust:
  //   "A feeling of revulsion or strong disapproval aroused by something unpleasant or offensive.",
  // "Empathetic pain":
  //   "The feeling of recognizing and understanding another person's pain.",
  // Entrancement:
  //   "A feeling of delight at being filled with wonder and enchantment.",
  // Excitement: "A feeling of great enthusiasm and eagerness.",
  // Fear:
  //   "An unpleasant emotion caused by the belief that someone or something is dangerous, likely to cause pain, or a threat.",
  // Horror: "An intense feeling of fear, shock, or disgust.",
  // Interest: "The state of wanting to know or learn about something or someone.",
  // Joy: "A feeling of great pleasure and happiness.",
  // Nostalgia:
  //   "A sentimental longing or wistful affection for the past, typically for a period or place with happy personal associations.",
  // Relief:
  //   "A feeling of reassurance and relaxation following release from anxiety or distress.",
  // Romance: "A feeling of excitement and mystery associated with love.",
  // Sadness: "Feeling sorrow or regret.",
  // Satisfaction:
  //   "Fulfillment of one's wishes, expectations, or needs, or the pleasure derived from this.",
  // "Sexual desire":
  //   "The motivational state and an interest in sexual objects or activities, or as a wish, or drive to seek out sexual objects or to engage in sexual activities.",
  // Surprise:
  //   "A feeling brought on by the discovery of an unexpected or astonishing event, fact, or thing.",

  'Hemit "Hikikomori"': "Will make an effort to not go outside",
  "Commuting outside":
    "Moving from home straight to work, not experiencing the outdoors consciously by not taking measures to be outside (in a car)",
  "Indirect necessity":
    "Being outside consciously because you have too (i.e. smoking, for social gatherings, working outside)",
  "Taking the scenic route":
    "Not full immersion but will take conscious steps to experience the environment for short amounts of time or being physically outside (riding a bike)",
  "Frequently outdoors":
    "Full immersion, not necessarily an activity but dedicated time to be outdoors",
  "Nature-lover":
    "Passion. Wanting and taking every opportunity to be in nature always",

  "Office Culture":
    "The mix of your company’s leadership, values, traditions, beliefs, interactions, behaviours and attitudes that contribute to the emotional and relational environment of your workplace.",
  Learning: "The ability to acquire new skills and become better informed",
  "Pay/Benefits":
    "The amount you are paid, and non-monetary benefits given to you by your job (e.g. paid time off, health insurance, retirement fund contributions, access to education, etc.)",
  "Career growth":
    "The ability to achieve higher status, be promoted to roles with more responsibility and greater rewards",
  Location:
    "The physical location of your office.  Is it close to home or in a fun, interesting area?",
  "Creative Stimulation": "Does your work allow you to use your creativity?",
  "Prestige/Status":
    "The amount of respect or admiration others may have for you based on your employer, position, or role.",
  // Convenience: "The ease of access of the job.",
  "Character & Personal Development":
    "The ability to grow as a person from doing your work or spending time around colleagues.",

  Food: "Groceries, eating out at restaurants, coffee, etc.",
  "Bills & Utilities":
    "Heating/cooling, electric, water, gas, phone bill, etc.",
  "Creative/Intellectual Endeavors":
    "Art, travel, books, supplies for hobbies, etc.",
  // Health:
  //   "Gym membership/ home gym equipment/ workout clothes/ doctors visits,etc.",

  Donation: "Donating to charities, politicians, online creators, etc.",
  Investing:
    "Retirement fund deposits, stocks, bonds, mutual funds, exchange-traded funds, certificates of deposit (CDs), annuities, crypto currencies, etc.",
  // Socializing:
  //   "Money spent while socializing. E.g. food bill from a date, coffee with friends, event tickets enjoyed with others, drinks with friends, going to the movies with someone, etc.",
  "Emergency fund": "Separate savings to use in case of emergency",
  Entertainment:
    "Netflix subscription, movies, sporting events, concerts, club cover fees, etc.",
  "Quality Goods": "Car, washing machine, dish washer, luxury items, etc.",
  "Nonessential Goods": "Clothes, knick-knacks, decorum, etc.",

  Naturalist: "Nature smart",
  Musical: "Sound smart",
  "Logical-Mathematical": "Number/reasoning smart",
  Existential: "Life smart",
  Interpersonal: "People smart",
  "Bodily-kinesthetic": "Body smart",
  Linguistic: "Word smart",
  "Intra-personal": "Self smart",
  Spatial: "Spatial reasoning, artistic skills, active imagination",

  Language: "Ability to speak native or foreign languages",
  "Math/Logic": "Ability to solve mathematical/logical problems.",
  Memory: "Ability to recall information",
  Creativity:
    "Ability to creatively express oneself or come up with new ideas.",
  "Patterns recognition": "Ability to notice and analyze patterns.",
  Multitasking:
    "Ability to do more than one thing at once while maintaining quality of execution.",
  "Problem Solving":
    "Ability to find solutions to difficult or complex issues.",
  Focus: "Ability to pay attention to a given task.",

  Recycling: "Using recycling bins, repurposing and reusing items.",
  Carpooling:
    "Driving with other people to school/work/social events to reduce the number of cars being driven and therefore reduce the amount of gasoline used.",
  "Sustainable vehicles": "Driving a hybrid, electric, or fuel efficient car",
  "Public transportation": "Taking a bus, train, or metro instead of driving",
  "Buying sustainable goods":
    "Buying local food, used clothes or fashion from sustainable brands, good made from recycled materials, etc.",
  "Not littering": "Disposing of waste in the proper receptacle.",
  "Being mindful":
    "Are you aware of how your actions affect the health of the environment?  Are you consciously making choices that either positively impact or reduce harm to the environment?",
  "Encouraging others to be mindful":
    "Do you advocate for the environment, reminding others to do their part as well?",
  "Donating financially/volunteering to causes":
    "Do you donate/support eco friendly companies/organizations? Do you volunteer your time for environmental causes?",
  "Educating yourself":
    "Do you make sure to educate yourself on environmental issues and different ways to live more sustainably?",
};
