import _ from "lodash";
import { bioSectionLength, questionnaireLength } from "../constants/common.js";
import questionMatrix from "./questionMatrix.js";

var answerMatrix = [];
var bio = [];

export const buildMatrix = (size) => {
  let matrix = [...Array(size)].fill(null);
  return matrix;
};

export const reset = () => {
  answerMatrix = buildMatrix(questionnaireLength);
  bio = buildMatrix(bioSectionLength);
};

export const getAnswer = (section, question) => {
  const idx = questionMatrix[section].questions[question].idx;
  if (idx) {
    return answerMatrix[idx - 1];
  } else {
    const bioIdx = questionMatrix[section].questions[question].bioIdx;
    return bio[bioIdx - 1];
  }
};

export const setAnswer = (section, question, value) => {
  const idx = questionMatrix[section].questions[question].idx;
  if (idx) {
    answerMatrix[idx - 1] = value;
  } else {
    const bioIdx = questionMatrix[section].questions[question].bioIdx;
    bio[bioIdx - 1] = value;
  }
};

export const getAnswerMatrix = () => {
  return _.concat(bio, answerMatrix);
};

export default answerMatrix;
