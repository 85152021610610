import { compiler } from "markdown-to-jsx";
import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../../css/accordionCard.css";
import { setTab } from "../../redux/actions";
const directory = "markdown/research/accordion/";

class HHAWCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const path = directory + this.props.idx + ".md";
    fetch(path)
      .then((response) => response.text())
      .then((text) => {
        var newState = { text };
        this.setState(newState);
      });
  }

  render() {
    var md = "<p>Loading...</p>";
    if (this.state.text) md = compiler(this.state.text);
    return (
      <Card className="accordion-card standard-border">
        <Accordion.Toggle
          className={
            this.props.activeKey === this.props.idx
              ? "accordion-card-selected standard-border"
              : ""
          }
          as={Card.Header}
          eventKey={this.props.idx}
        >
          <h4>
            {this.props.idx}. {this.props.title}.
          </h4>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.idx}>
          <Card.Body className="card-content">
            <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>{md}</div>
            <Row>
              <Col lg={4} md={3} xs={4} />
              <Col lg={4} md={6} xs={4}>
                <div className="card-content-anchor-div">
                  <a
                    className="card-content-anchor"
                    href="#report"
                    onClick={() => {
                      this.props.setTab(
                        this.props.reportSectionName.toLowerCase()
                      );
                    }}
                  >
                    <div className="hhaw-button standard-border rd-invert-color ">
                      learn more
                    </div>
                  </a>
                </div>
              </Col>
              <Col lg={4} md={3} xs={4} />
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default connect(null, { setTab })(HHAWCard);
