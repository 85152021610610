import { motion, useAnimation } from "framer-motion";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import "../../css/circleRotate.css";

//creates a circular div that "opens" from a quarter circle
// props
// outerColor
// innerColor
// fontSize
// text

function getContent(props) {
  if (props.useH1) {
    return (
      <h1
        dangerouslySetInnerHTML={{ __html: props.text }}
        style={{ fontSize: props.fontSize }}
      ></h1>
    );
  } else {
    return (
      <h3
        dangerouslySetInnerHTML={{ __html: props.text }}
        style={{ fontSize: props.fontSize }}
      ></h3>
    );
  }
}

const intialDelay = 0.75;

const sequence = async (topSemi, leftSemi) => {
  await topSemi.start({
    // usingt 89 makes it slightly less jittery
    rotate: -89,
    transitionEnd: {
      opacity: 0,
      zIndex: 100,
    },
    transition: { delay: intialDelay, duration: 0.5, ease: "linear" },
  });

  return leftSemi.start({
    rotate: -180,
    transitionEnd: {
      opacity: 0,
    },
    transition: {
      duration: 1,
      ease: "linear",
    },
  });
};

export default function CircleRotate(props) {
  const [displayText, setDisplayText] = useState(false);

  const topSemi = useAnimation();
  const leftSemi = useAnimation();

  const rightSemiOpacity = displayText ? 0 : 1;

  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) sequence(topSemi, leftSemi);
      }}
    >
      <div
        className="rd-circle"
        style={{ backgroundColor: props.innerColor }}
        ref={props.forwardedRef}
      >
        {displayText ? (
          <motion.div
            style={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
          >
            {getContent(props)}
          </motion.div>
        ) : null}

        <div
          style={{
            backgroundColor: props.innerColor,
            opacity: rightSemiOpacity,
          }}
          className="right-semicircle"
        />

        <motion.div>
          <motion.div
            style={{
              backgroundColor: props.outerColor,
              outlineColor: props.outerColor,
              originX: 0.5,
              originY: 1,
            }}
            className="top-semicircle"
            animate={topSemi}
          />
          <motion.div
            style={{
              backgroundColor: props.outerColor,
              outlineColor: props.outerColor,
              originX: 1,
              originY: 0.5,
            }}
            className="left-semicircle"
            animate={leftSemi}
            onAnimationComplete={() => setDisplayText(true)}
          />
        </motion.div>
      </div>
    </VisibilitySensor>
  );
}
