import classNames from "classnames";
import _ from "lodash";
import React from "react";
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { Chart } from "react-google-charts";
import { colors } from "../../matrices/colorPalette.js";
import questionMatrix from "../../matrices/questionMatrix.js";

const xExclusions = [
  "Age",
  "Meal Priorities",
  "Geographic Culture",
  "Substances",
  "Usage",
  "Screen Time",
  "Elements Of Health",
  "Idealized Health",
];

const bioVars = _.chain(questionMatrix[0].questions)
  .map("name")
  .without(...xExclusions)
  .value();

const quizVars = {
  8: "What phrases are the most appealing to you?",
  16: "Are you currently happy with the way you look physically?",
  24: "Do you feel your actions and opinions are valuable to the world?",
  27: "How often do you get a good hug(s) from another person?",
  37: "How do you feel after spending time on social media?",
  43: "Is your job challenging/stimulating/fulfilling, if so, in what way?",
  49: "On your off time, would you prefer to learn or chill?",
  38: "How often do you think you should be immersed in nature?",
};

const xVariables = _.concat(bioVars, _.keys(quizVars));
const placeQuestion = "City";

const togglesPerRow = 4;

class Sankey extends React.Component {
  constructor(props) {
    super(props);

    var filters = {};
    _.forEach(this.props.data, (row) => {
      _.forEach(xVariables, (x) => {
        if (!filters[x]) filters[x] = new Set();
        if (x === placeQuestion) filters[x].add(row[x]);
        else _.forEach(row[x].split(","), (val) => filters[x].add(val));
      });
    });

    this.state = {
      categories: new Set(_.sampleSize(xVariables, 2)),
      activeFilters: {},
      weights: [["From", "To", "Respondants"]],
      filters,
    };
  }

  componentDidMount() {
    this.generateSankeyData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.activeFilters !== this.state.activeFilters ||
        prevState.categories.size !== this.state.categories.size) &&
      this.state.categories.size >= 2
    ) {
      this.generateSankeyData();
    }
  }

  generateSankeyData() {
    // for each pair [0,1], [1,2], [2,1], etc...
    // lhs and rhs
    // go through the rows, mark every lhs and rhs instance in an object
    // for each lhs, create an array for each rhs with the correct weight value

    var weights = [["From", "To", "Respondants"]];
    var categories = Array.from(this.state.categories);

    const indices = _.range(0, categories.length - 1);
    _.forEach(indices, (i) => {
      var j = i + 1;

      const weightTable = {};
      //TODO: try and optimize this
      _.forEach(this.props.data, (row) => {
        var lhs = row[categories[i]].split(",");
        if (categories[i] === placeQuestion) lhs = [row[categories[i]]];

        var rhs = row[categories[j]].split(",");
        if (categories[j] === placeQuestion) rhs = [row[categories[j]]];

        // if a filter is set, only extract values that match it
        if (this.state.activeFilters[categories[i]])
          lhs = _.filter(
            lhs,
            (val) => val === this.state.activeFilters[categories[i]]
          );

        if (this.state.activeFilters[categories[j]])
          rhs = _.filter(
            rhs,
            (val) => val === this.state.activeFilters[categories[j]]
          );

        _.forEach(lhs, (lhsVal) => {
          _.forEach(rhs, (rhsVal) => {
            if (!weightTable[lhsVal]) weightTable[lhsVal] = {};

            if (weightTable[lhsVal][rhsVal]) weightTable[lhsVal][rhsVal] += 1;
            else weightTable[lhsVal][rhsVal] = 1;
          });
        });
      });

      const nextWeights = _.flatMap(weightTable, (val, lhsVal) =>
        _.map(val, (weight, rhsVal) => [lhsVal, rhsVal, weight])
      );

      weights = _.concat(weights, nextWeights);
    });

    this.setState({ weights });
  }

  generateDropdown(category) {
    const defaultTitle = isNaN(category) ? category : quizVars[category];
    return (
      <DropdownButton
        as={ButtonGroup}
        key={category}
        variant={this.state.activeFilters[category] ? "success" : "secondary"}
        title={
          this.state.activeFilters[category]
            ? this.state.activeFilters[category]
            : defaultTitle
        }
        style={{ margin: "1%" }}
        drop="up"
      >
        {_.map(Array.from(this.state.filters[category]), (filter) => {
          return (
            <Dropdown.Item
              key={filter}
              eventKey={filter}
              onClick={() => this.dropdownOnClick(category, filter)}
            >
              {filter}
            </Dropdown.Item>
          );
        })}
        <Dropdown.Divider />
        <Dropdown.Item
          key="clearFilter"
          eventKey="clearFilter"
          onClick={() => this.dropdownOnClick(category, "clear")}
        >
          Clear Filter
        </Dropdown.Item>
      </DropdownButton>
    );
  }

  dropdownOnClick(category, filter) {
    var activeFilters = Object.assign({}, this.state.activeFilters);
    if (filter === "clear") delete activeFilters[category];
    else activeFilters[category] = filter;
    this.setState({ activeFilters });
  }

  toggleHandler(category) {
    var categories = new Set(this.state.categories);
    if (categories.has(category)) {
      categories.delete(category);
    } else {
      categories.add(category);
    }

    var activeFilters = Object.assign({}, this.state.activeFilters);
    activeFilters[category] = null;

    this.setState({ categories, activeFilters });
  }

  generateCategoryToggles() {
    return _(xVariables)
      .chunk(togglesPerRow)
      .map((row) => {
        return (
          <Row noGutters={true} key={_.join(row, "-")}>
            {_.map(row, (category) => {
              const colClass = classNames(
                "sankey-category",
                "standard-border",
                {
                  "sankey-category-active": this.state.categories.has(category),
                }
              );
              return (
                <Col
                  key={category}
                  className={colClass}
                  onClick={() => this.toggleHandler(category)}
                >
                  <h4>{isNaN(category) ? category : quizVars[category]}</h4>
                </Col>
              );
            })}
          </Row>
        );
      })
      .value();
  }

  render() {
    return (
      <div>
        <Row noGutters={true} className="standard-border sankey-header">
          <h3>Select the categories you want to see connected.</h3>
        </Row>
        <div>{this.generateCategoryToggles()}</div>

        <Row noGutters={true} className="standard-border">
          <Col>
            {this.state.categories.size > 1 ? (
              <Chart
                key="hhaw-sankey"
                style={{ margin: "2%" }}
                // width={"100%"}
                height={"40vh"}
                chartType="Sankey"
                loader={
                  <div className="sankey-placeholder">
                    <h3>Loading Chart</h3>
                  </div>
                }
                data={this.state.weights}
                options={{
                  sankey: {
                    link: { colors, colorMode: "gradient" },
                    node: {
                      colors,
                      width: 5,
                      label: { color: "white", fontSize: 12 },
                      labelPadding: 10,
                      nodePadding: 20,
                      interactivity: "true",
                    },
                    chartArea: { width: "100%", height: "100%" },
                  },
                }}
                rootProps={{ "data-testid": "2" }}
              />
            ) : (
              <div className="sankey-placeholder">
                <h3>Select at least 2 categories :)</h3>
              </div>
            )}
          </Col>
        </Row>
        <Row noGutters={true} className="standard-border sankey-header">
          <h3>Add filters to the vizualization.</h3>
        </Row>
        <Row noGutters={true} className="standard-border">
          {_.size(this.state.filters) > 0
            ? _.map(Array.from(this.state.categories), (cat) =>
                this.generateDropdown(cat)
              )
            : null}
        </Row>
      </div>
    );
  }
}

export default Sankey;
