import _ from "lodash";
import { compiler } from "markdown-to-jsx";
import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../../css/tabbedReport.css";
import { setTab } from "../../redux/actions";
import { getTab } from "../../redux/selectors";

//these should be props

const defaultContent = "coming-soon.md";

class TabbedReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tab: this.props.tabs[0] };
  }

  async getMarkdown(path) {
    return fetch(path)
      .then((response) => response.text())
      .then((text) => text);
  }

  async componentDidMount() {
    if (this.props.noContentFlag) {
      fetch(defaultContent)
        .then((response) => response.text())
        .then((text) => {
          var newState = { defaultMd: text };
          this.setState(newState);
        });
    } else {
      const markdowns = _.map(this.props.tabs, async (tab) => {
        return {
          tab,
          text: await this.getMarkdown(this.props.markdownPath + tab + ".md"),
        };
      });

      Promise.all(markdowns).then((resolvedMarkdowns) => {
        const newState = _.reduce(
          resolvedMarkdowns,
          (acc, obj) => {
            const { tab, text } = obj;
            acc[tab] = text;
            return acc;
          },
          {}
        );
        this.setState(newState);
      });
    }
  }

  getNavs() {
    return _.map(this.props.tabs, (tab) => (
      <Nav.Item key={tab}>
        {this.props.controlled ? (
          <Nav.Link
            className="report-nav-item"
            eventKey={tab}
            onSelect={() => this.props.setTab(tab)}
          >
            {tab}.
          </Nav.Link>
        ) : (
          <Nav.Link
            className="report-nav-item"
            eventKey={tab}
            onSelect={() => this.setState({ tab })}
          >
            {tab}.
          </Nav.Link>
        )}
      </Nav.Item>
    ));
  }

  render() {
    var md = <div></div>;
    if (this.props.noContentFlag && this.state.defaultMd) {
      //if we do not currently have content yet for the component
      md = compiler(this.state.defaultMd);
    } else if (this.props.controlled && this.state[this.props.tab]) {
      // if component is controlled by redux
      md = compiler(this.state[this.props.tab]);
    } else if (this.state[this.state.tab]) {
      //if component manages its own state
      md = compiler(this.state[this.state.tab]);
    }

    return (
      <div className="tabbed-report">
        <Row noGutters={true}>
          <Col />
          <Col md={12}>
            {this.props.controlled ? (
              <Nav
                fill
                className="justify-content-center report-nav"
                variant="tabs"
                activeKey={this.props.tab}
              >
                {this.getNavs()}
              </Nav>
            ) : (
              <Nav
                fill
                className="justify-content-center report-nav"
                variant="tabs"
                activeKey={this.state.tab}
              >
                {this.getNavs()}
              </Nav>
            )}
          </Col>
          <Col />
        </Row>
        {this.props.story ? (
          <Row noGutters={true} style={{ flex: 1 }}>
            <Col>
              <div className="standard-border report-content">
                <Col md={10}>{md}</Col>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col />
            <Col>
              <div className="standard-border report-content">{md}</div>
            </Col>
            <Col />
          </Row>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    tab: getTab(state),
  }),
  { setTab }
)(TabbedReport);
