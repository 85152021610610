import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import {
  appendInfo,
  popInfo,
  setInfo,
  setQuestion,
  setQuizDone,
  setSection,
} from "../../redux/actions";
import Question from "./Question";
import {
  answerChangeHandler,
  generateHeader,
  removeEnterKeyListener,
  setEnterKeyListener,
} from "./questionUtils.js";

class PercentageQuestion extends Question {
  constructor(props) {
    super(props);
    const initialPercentage = (100.0 / props.question.fields.length).toFixed(2);
    // const initialPercentage = 0.0;
    const initialAnswer = _.reduce(
      props.question.fields,
      (aggregate, field) => {
        aggregate[field] = initialPercentage;
        return aggregate;
      },
      {}
    );
    this.state = Object.assign(this.state, {
      answer: initialAnswer,
      initialPercentage,
    });
  }

  componentDidUpdate() {
    // allow user to proceed if they have filled out a field
    const userHasSetField = _.reduce(
      this.state.answer,
      (aggregate, val, field) =>
        aggregate || val !== this.state.initialPercentage,
      false
    );

    answerChangeHandler(this.props, this.state.answer);

    if (!this.state.shouldDisplayNext && userHasSetField)
      this.setState({ shouldDisplayNext: true });

    if (this.state.shouldDisplayNext)
      setEnterKeyListener(this.enterKeyListenerCallback);
    else removeEnterKeyListener(this.enterKeyListenerCallback);
  }

  inputOnChange(field, value) {
    const newAnswer = this.state.answer;
    newAnswer[field] = value;
    this.setState({
      answer: newAnswer,
    });
  }

  generateInputs() {
    return _.map(this.props.question.fields, (field) => {
      return (
        <li style={{ display: "flex", justifyContent: "flex-start" }}>
          <span>
            <p style={{ fontSize: "calc(.5rem + .4vw)" }}>{field}: </p>
          </span>
          <span>
            <input
              className="percentage-text-input"
              placeholder="%"
              // value={this.state.answer[field] + "%"}
              onChange={(e) => this.inputOnChange(field, e.target.value)}
            ></input>
          </span>
        </li>
      );
    });
  }

  render() {
    var chartData = _.map(this.state.answer, (val, key) => [
      key,
      parseFloat(val),
    ]);
    chartData = [["Field", "Value"], ...chartData];
    return (
      <div>
        <div>{generateHeader(this.props, this.state)}</div>
        <Container className="answer-box d-flex flex-column">
          <Row className="h-100" noGutters={true}>
            <Col md={8} className="flex-grow-1">
              <Chart
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={chartData}
                width={"100%"}
                height={"100%"}
                options={{
                  pieHole: 0.5,
                  pieSliceTextStyle: {
                    color: "white",
                    fontName: "CircularStd",
                  },
                  backgroundColor: "black",
                  chartArea: { width: "90%", height: "70%" },
                  legend: {
                    position: "left",
                    alignment: "center",
                    textStyle: {
                      color: "white",
                      fontSize: 10,
                      fontName: "CircularStd",
                    },
                  },
                  tooltip: {
                    textStyle: {
                      color: "black",
                    },
                  },
                  pieSliceText: "none",
                  sliceVisibilityThreshold: 0,
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </Col>
            <Col md={4} className="d-flex flex-column justify-content-center">
              <ul className="percentage-input-list">{this.generateInputs()}</ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(null, {
  setSection,
  setQuestion,
  setInfo,
  appendInfo,
  popInfo,
  setQuizDone,
})(PercentageQuestion);
