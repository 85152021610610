import _ from "lodash";
import { combineReducers } from "redux";
import "./actions.js";
import {
  hoverInfoActions,
  landingActions,
  questionaireProgress,
  quizActions,
  researchActions,
} from "./actions.js";

const quizInitialState = {
  section: 0,
  question: 0,
};

const questionnaire = (state, action) => {
  if (_.isEmpty(state)) {
    return Object.assign({}, state, quizInitialState);
  }

  switch (action.type) {
    case questionaireProgress.SET_SECTION:
      return Object.assign({}, state, {
        section: action.idx,
      });

    case questionaireProgress.SET_QUESTION:
      return Object.assign({}, state, {
        question: action.idx,
      });

    case questionaireProgress.SET_PROGESS:
      return Object.assign({}, state, {
        section: action.section,
        question: action.question,
      });

    case quizActions.RESET:
      return Object.assign({}, state, quizInitialState);

    default:
      return state;
  }
};

const hoverInfoInitialState = [];

const hoverInfo = (state = hoverInfoInitialState, action) => {
  switch (action.type) {
    case hoverInfoActions.CLEAR:
      return [];
    case hoverInfoActions.POP:
      return state.slice(-1);
    case hoverInfoActions.SET:
      return [action.tip];
    case hoverInfoActions.APPEND:
      return [action.tip, ...state];
    case quizActions.RESET:
      return hoverInfoInitialState;
    default:
      return state;
  }
};

const quizDone = (state = false, action) => {
  switch (action.type) {
    case quizActions.DONE:
      return true;
    case quizActions.RESET:
      return false;
    default:
      return state;
  }
};

const landing = (state = "", action) => {
  switch (action.type) {
    case landingActions.SELECT_DIMENSION:
      return action.dimension;
    default:
      return state;
  }
};

const research = (state = "full", action) => {
  switch (action.type) {
    case researchActions.SET_TAB:
      return action.tab;
    default:
      return state;
  }
};

export default combineReducers({
  questionnaire,
  hoverInfo,
  quizDone,
  landing,
  research,
});
