import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../css/dimensions.css";

const dimensionBriefs1 = {
  physical:
    "Recognizing the need for physical activity, balanced diet, and sleep.",
  emotional:
    "Coping effectively with life and creating satisfying relationships.",
  spiritual: "Expanding a sense of purpose and meaning in life.",
  social:
    "Developing a sense of connections, belonging, and a well-developed support system",
};

const dimensionBriefs2 = {
  environmental:
    "Good health by occupying pleasant, stimulating environments that support well-being.",
  vocational: "Personal satisfcation and enrichment from one's work",
  financial: "Satisfcation with current and future financial situations.",
  intellectual:
    "Recognizing creative abilities and finding ways to expand knowledge and skills",
};

function generateTableEntries(props, briefs) {
  return _.map(briefs, (brief, dimension) => {
    const tdClass = classNames("dimension-brief", {
      "selected-dimension": dimension === props.selected,
    });
    return (
      <td id={dimension} key={dimension} className={tdClass}>
        <p>{brief}</p>
      </td>
    );
  });
}

function Dimensions(props) {
  return (
    <Row noGutters={true}>
      <Col md={3}>
        <div className="standard-border dimensions-header">
          <div className="header-text standard-border dimensions-title">
            <h1>The 8 dimensions of wellness.</h1>
          </div>
          <div className="dimensions-description-wrapper">
            <Row>
              <div className="header-text dimensions-description">
                <h2>why do the 8 dimensions exist?</h2>
                <h3>
                  The Substance Abuse and Mental Health Services Administration
                  (SAMHSA) has identified eight dimensions or wellness to focus
                  on to optimize health.
                </h3>
              </div>
            </Row>
            <Row className="dimensions-button-row">
              <Col lg={1} md={0} xs={3} />
              <Col lg={10} md={11} xs={6}>
                <Link to="/rd">
                  <div className="hhaw-button standard-border invert-color">
                    learn more
                  </div>
                </Link>
              </Col>
              <Col lg={1} md={0} xs={3} />
            </Row>
          </div>
        </div>
      </Col>
      <Col md={9}>
        <Table
          responsive
          bordered
          className="dimensions-table"
          id="dimensions"
          size="md"
        >
          <thead>
            <tr>
              <th>1_ physical</th>
              <th>2_ emotional</th>
              <th>3_ spiritual</th>
              <th>4_ social</th>
            </tr>
          </thead>
          <tbody>
            <tr>{generateTableEntries(props, dimensionBriefs1)}</tr>
          </tbody>
          <thead>
            <tr>
              <th>5_ environmental</th>
              <th>6_ vocational</th>
              <th>7_ financial</th>
              <th>8_ intellectual</th>
            </tr>
          </thead>
          <tbody>
            <tr>{generateTableEntries(props, dimensionBriefs2)}</tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default Dimensions;
