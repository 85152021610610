import axios from "axios";
import _ from "lodash";

const route = "/api/place";
// const env = process.env.NODE_ENV || "development";

//TODO: Holy hack batman
var baseURL = process.env.VERCEL_URL || "https://howhealthyarewe.com";
const path = baseURL + route;
console.log(path);

export async function getPlaceSuggestions(input) {
  try {
    const response = await axios.get(path, {
      params: {
        input,
      },
    });
    console.log(response);
    return _.map(response.data.predictions, "description");
  } catch (error) {
    console.error(error);
  }
}
