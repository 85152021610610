import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getPlaceSuggestions } from "../../utils/placeAPI";

export default function PlaceTypeahead(props) {
  const { question, onBlur, onChange, answer } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const searchHandler = async (query) => {
    setIsLoading(true);
    const options = await getPlaceSuggestions(query);
    setOptions(options);
    setIsLoading(false);
  };

  return (
    <AsyncTypeahead
      id="async-typeahead"
      isLoading={isLoading}
      placeholder={question.placeholder}
      onBlur={onBlur}
      onSearch={searchHandler}
      onChange={onChange}
      options={options}
      selected={answer ? answer : []}
    />
  );
}
