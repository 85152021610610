import React from "react";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

export default function MobileModal(props) {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  return (
    <Modal style={{ color: "black" }} show={isTabletOrMobileDevice}>
      <Modal.Body>
        <p>
          Hey there! Thanks for visiting How Healthy Are We! <br></br> <br></br>{" "}
          We want you to have the best user experience possible, and that will
          be on a laptop or desktop. Come back and take our quiz!
        </p>
      </Modal.Body>
    </Modal>
  );
}
