import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../css/research.css";
import questionMatrix from "../matrices/questionMatrix";
import { getSelection } from "../redux/selectors";
import DimensionsNav from "./common/DimensionsNav";
import NavBar from "./common/NavBar";
import TabbedReport from "./common/TabbedReport.js";
import TitleBox from "./common/TitleBox";
import Accordion from "./research/Accordion";

const markdownPath = "markdown/research/report/";
const tabs = [
  "full",
  "emotional",
  "physical",
  "physical perception",
  "social",
  "web social",
  "spiritual",
  "intellectual",
  "financial",
  "vocational",
  "environmental",
];

function Research(props) {
  var questions = questionMatrix.slice(1);
  if (props.selected && props.selected !== "") {
    questions = _.filter(
      questionMatrix,
      (section) => section.name === props.selected.toUpperCase()
    );
  }

  return (
    <div>
      <Container fluid className="hhaw-container no-padding">
        <Row noGutters={true}>
          <Col md={5} className="landing-col">
            <TitleBox />

            <DimensionsNav />
          </Col>
          <Col md={7} className="landing-col d-flex flex-column">
            <NavBar />
            <Row noGutters={true} className="research-atf">
              <Col className="standard-border d-flex flex-column">
                <div className="accordion-header">
                  <h1>Questions.</h1>
                </div>
                <div className="hhaw-accordion-div flex-grow-1">
                  <Accordion content={questions} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="no-padding hhaw-container" id="report">
        <Row className="break"></Row>
        <TabbedReport tabs={tabs} markdownPath={markdownPath} controlled />
      </Container>
    </div>
  );
}

export default connect(
  (state) => ({
    selected: getSelection(state),
  }),
  null
)(Research);
