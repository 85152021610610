import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../../css/dimensionsNav.css";
import { setSelection } from "../../redux/actions";

const row1 = ["physical", "emotional", "spiritual"];
const row2 = ["social", "environmental", "vocational"];
const row3 = ["financial", "intellectual"];

function generateRow(props, dimensions, idx) {
  const cols = _.map(dimensions, (dimension) => {
    idx++;
    return (
      <Col lg={4} md={4} sm={4} xs={4} key={"dimensions-nav-" + dimension}>
        <a
          href={props.link ? "#dimensions" : "#"}
          onClick={() => props.setSelection(dimension)}
        >
          <div
            id={"dimension-" + idx}
            className="dimension-text standard-border"
          >
            <h4>
              {idx}_ {dimension}
            </h4>
          </div>
        </a>
      </Col>
    );
  });
  return (
    <Row noGutters={true} className="dimensions-row">
      {cols}
    </Row>
  );
}

class DimensionsNavBar extends React.Component {
  componentWillUnmount() {
    this.props.setSelection("");
  }

  render() {
    return (
      <div>
        {generateRow(this.props, row1, 0)}
        {generateRow(this.props, row2, 3)}
        {generateRow(this.props, row3, 6)}
      </div>
    );
  }
}

export default connect(null, { setSelection })(DimensionsNavBar);
