import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import questionMatrix from "../../matrices/questionMatrix.js";
import {
  appendInfo,
  popInfo,
  setInfo,
  setQuestion,
  setQuizDone,
  setSection,
} from "../../redux/actions";
import Question from "./Question";
import {
  answerChangeHandler,
  generateFields,
  generateHeader,
  getSprite,
  removeEnterKeyListener,
  setEnterKeyListener,
} from "./questionUtils.js";

// props:
// sectionIdx;
// questionIdx;

// mark style questions have a list of fields

class CircleQuestion extends Question {
  genRow(fields, state) {
    const question =
      questionMatrix[this.props.sectionIdx].questions[this.props.questionIdx];

    var cols = _.map(fields, (field) => {
      var tooltip = field;
      if (question.tooltips) {
        tooltip = question.tooltips[field];
      }

      const markButtonCol = classNames("mark-button-col", "standard-border", {
        "highlight-circle": state.answer && state.answer === field,
      });

      var sprite;
      if (this.props.question.sprites) sprite = getSprite(this.props, field);

      return (
        <Col
          key={field}
          md={2}
          className={markButtonCol}
          onMouseEnter={() => this.props.appendInfo(tooltip)}
          onMouseLeave={() => this.props.popInfo()}
        >
          <div
            id={field}
            className="mark-button"
            onClick={() => this.handleSelection(field)}
          >
            <p> {field}</p>
            {this.props.question.sprites ? (
              <div
                className="sprite"
                style={{
                  content: sprite,
                }}
              />
            ) : null}
          </div>
        </Col>
      );
    });

    const paddingSize = this.getPaddingSize(question.fields.length);

    return (
      <Row className="mark-button-row">
        <Col md={paddingSize} />
        {cols}
        <Col md={paddingSize} />
      </Row>
    );
  }

  getPaddingSize(numFields) {
    if (numFields === 3) {
      return 3;
    } else if (numFields === 2) {
      return 4;
    }
    return 1;
  }

  handleSelection(field) {
    if (field === this.state.answer) {
      //unselelct
      this.setState({ answer: null });
    } else {
      this.setState({ answer: field });
    }
  }

  componentDidUpdate() {
    if (!this.state.shouldDisplayNext && this.state.answer)
      this.setState({ shouldDisplayNext: true });
    else if (this.state.shouldDisplayNext && !this.state.answer)
      this.setState({ shouldDisplayNext: false });

    answerChangeHandler(this.props, this.state.answer);

    if (this.state.shouldDisplayNext)
      setEnterKeyListener(this.enterKeyListenerCallback);
    else removeEnterKeyListener(this.enterKeyListenerCallback);
  }

  render() {
    const fieldsPerRow = 5;

    return (
      <div>
        <div>{generateHeader(this.props, this.state)}</div>
        <Container className="answer-box">
          {generateFields(
            this.props,
            this.state,
            this.genRow.bind(this),
            fieldsPerRow
          )}
        </Container>
      </div>
    );
  }
}

export default connect(null, {
  setSection,
  setQuestion,
  setInfo,
  appendInfo,
  popInfo,
  setQuizDone,
})(CircleQuestion);
