import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../css/story.css";
import DimensionsNav from "./common/DimensionsNav";
import NavBar from "./common/NavBar";
import TabbedReport from "./common/TabbedReport.js";
import TitleBox from "./common/TitleBox";

const markdownPath = "markdown/story/atf/";
const tabs = ["project", "challenge", "outcome"];

const markdownPathBtf = "markdown/story/report/";
const tabsBtf = ["abstract", "value prop", "market", "methods" /*"strategy"*/];

function Story(props) {
  return (
    <div>
      <Container fluid className="hhaw-container no-padding">
        <Row noGutters={true}>
          <Col md={5} className="landing-col">
            <TitleBox />

            <DimensionsNav />
          </Col>
          <Col md={7} className="landing-col d-flex flex-column">
            <NavBar />
            <Row noGutters={true} className="flex-grow-1">
              <Col className="standard-border d-flex flex-column">
                <div className="story-header">
                  <h1>HHAW.</h1>
                </div>
                <div className="story-tabs">
                  <TabbedReport tabs={tabs} markdownPath={markdownPath} story />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="no-padding hhaw-container" id="report">
        <Row className="break"></Row>
        <TabbedReport tabs={tabsBtf} markdownPath={markdownPathBtf} />
      </Container>
    </div>
  );
}

export default Story;
